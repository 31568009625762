import {React,useEffect} from 'react';
import { Footer, Navbar } from "..";

const ReturnPolicy=()=> {
    let data=JSON.parse(localStorage.getItem("policies"));
    useEffect(() => {
        window.scrollTo(0, 0);
    })
  return (
    <>
    <Navbar />
    <div className="container pt-3">
        {data.return_policy && <div dangerouslySetInnerHTML={{ __html: data.return_policy}} />}
    </div>
    <Footer />
    </> 
  )
}
export {ReturnPolicy}
