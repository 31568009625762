import React, { useState, useEffect } from "react";
import "./SubCategories.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate, NavLink, useParams } from "react-router-dom";

const SubCategories = (props) => {
    const { category_id, category_slug } = props;
    const [loading, setLoading] = useState(false)
    const [Data, SetData] = useState([])
    const Navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const { friendlyurl } = useParams();

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    const HandleNavigate = (path) => {
        Navigate(path)
    }

    const GetData = async (category_id) => {

        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/sub-categories/?category=${category_id}&is_suspended=false&page_size=100`)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData(category_id);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [category_id])
    console.log(props)
    return (
        <>{
            isMobile ?
                <div className="categories-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('../images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {
                                Data.map((itemObj, index) => {
                                    return (
                                        <div className={`subcat-item-container ${itemObj.slug === friendlyurl ? 'active' : ''}`} key={index} onClick={() => HandleNavigate(`/${category_slug}/${itemObj.slug}`)}>
                                            {/* <NavLink to={`/${category_slug}/${itemObj.slug}`} > */}
                                            <div className="text-center ismobile-relclass relclass">
                                                <img src={itemObj.image && itemObj.image.image} />
                                                <p className="sub-p">{itemObj.name}</p>
                                            </div>
                                            {/* </NavLink> */}
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div> :

                <div className="categories-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('../images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {
                                Data.map((itemObj, index) => {
                                    return (
                                        <div className={`subcat-item-container ${itemObj.slug === friendlyurl ? 'active' : ''}`} key={index} onClick={() => HandleNavigate(`/${category_slug}/${itemObj.slug}`)}>
                                            {/* <NavLink to={`/${category_slug}/${itemObj.slug}`} > */}
                                            <div className="row align-items-center">
                                                <div className="col-3">
                                                    <img src={itemObj.image && itemObj.image.image} />
                                                </div>
                                                <div className="col-9 sub-p-container">
                                                    <p className="sub-p">{itemObj.name}</p>
                                                </div>
                                            </div>
                                            {/* </NavLink> */}
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div>
        }
        </>
    )
}
export { SubCategories };