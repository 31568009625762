import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer/Footer";
import axiosConfig from "../../Service/axiosConfig";
const AboutUs = () => {
  const [formData, setFormData] = useState({});
  const site_data = JSON.parse(localStorage.getItem("site_data"));
  
  
  const GetPolicies = async () => {
    try {
      const responce = await axiosConfig.get(`/catlog/policies/`);
      console.log(responce.data.results)
      setFormData(responce.data.results[0])
    } catch (error) {
      console.log(error)
    }
  }
 useEffect(() => {
    GetPolicies()
  }, [])
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  useEffect(()=>{
        if(site_data)
          {
            // Create description meta tag
            const Description = document.createElement('meta');
            Description.setAttribute('property', 'description');
            Description.setAttribute('content', site_data.meta_description);
            {console.log(site_data.meta_description)}
            document.head.appendChild(Description);

          return()=>{
            document.head.removeChild(Description);
            
          }
 }
},[])
 return (
    <>
   <Navbar />
      <div className="container pt-3">
        {formData.about_us && <div dangerouslySetInnerHTML={{ __html: formData.about_us }} />}
      </div>
      <Footer />
    </>
  )
}
export { AboutUs }