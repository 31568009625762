import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { addProduct, selectCartProducts, selectCartTotal, removeProduct, fetchProducts } from "../../redux/Slices/cartSlice";
import { MdAdd, MdRemove, MdOutlineShoppingBag } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci"
import { TfiTimer } from "react-icons/tfi";
import axiosConfig from "../../Service/axiosConfig";
import { Products } from "../Products";
import { BsFileTextFill } from "react-icons/bs";
import { RiMotorbikeFill } from "react-icons/ri";
import { TbArrowBigUpLines } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
const ViewCart = ({ handleClose, setOrders, Order, setTotalAmount, orderAddress, productAmount, travelTime }) => {
  const products = useSelector(selectCartProducts);
  const total = useSelector(selectCartTotal);
  const dispatch = useDispatch();
  let Navigate = useNavigate()
  let ProductData = []
  const handleRemove = (productId, quantity) => {
    dispatch(removeProduct({ productId, quantity }));
  };

  const handleShopping = () => {
    Navigate('/')
    handleClose()
  }

  const addToCart = (product) => {
    dispatch(addProduct({ product: product, quantity: 1 }));
    console.log("added")
  };
  useEffect(() => {
    console.log(productAmount)
    products.forEach(res => {
      ProductData.push({
        "id": res.id,
        "min_qty": res.quantity,
      })
    })
    setOrders && setOrders({ ...Order, "products": ProductData })
    // const serializedState = JSON.stringify(products);
    // products.length > 0 && localStorage.setItem('state', serializedState)
  }, [products])
  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])
  return (
    <div>
      {products.length === 0 ? (
        <div className="Empty-cart">
          <div>
            <img className="empty-img" src={require("../Navbar/images/emp_empty_cart.png")} alt="emp" />
          </div>
          <p className="p-delivery-time">You don't have any items in your cart</p>
          <p className="message-cart">Your favourite items are just a click away</p>
          <button type="button" className="btn--link" onClick={() => handleShopping()}>Start Shopping</button>
          {/* <p className="p-qty">{cart.products.length} items</p> */}
        </div>
      ) : (
        <>
          <div className="bill-details py-2">
            <div className="cart-timer">
              <div className="cart-timer-icon">
                <TfiTimer size={24} />
              </div>
              <div>
                {localStorage.getItem("site_data") && JSON.parse(localStorage.getItem("site_data")).ecommerce_type !== "Conventional" && <p className="p-delivery-time">{travelTime ? `Delivery in  ${travelTime ? travelTime : 10} minutes` : "Delivery to"}</p>}
                <p className="p-qty">Shipment of {products.length} items</p>
              </div>
            </div>
            {products.map((product) => (
              <div className="productItem" key={product.id}>
                <div className="row">
                  <div className="col-2">
                    <img src={product.image ? product.image.image : product.imagef} className="cart-display-img" />
                  </div>
                  <div className="col-6">
                    <p>{product.name}</p>
                    {product.weight_in_kg && product.weight_in_kg > 0 ? (
                      <>
                        <p>{product.weight_in_kg}</p>
                      </>
                    ) : (<></>)}
                    <div className="d-flex">
                      {product.offer_price && product.offer_price > 0 ? (
                        <>
                          <p className="cart-item-price">₹{product.offer_price}</p>
                          <p className={product.offer_price ? "produc-price ms-2" : "cart-item-price"}>₹{product.price}</p>
                        </>
                      ) : (
                        <p className="cart-item-price">₹{product.price}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="p-btn">
                      <div className="btn btn-cart btn-cart-added">
                        <button className="btn-minus" onClick={() => handleRemove(product.id, 1)}>
                          <div className="custom-minus"></div>
                        </button>
                        <div className="qty">{product.quantity}</div>
                        <button className="btn-minus" disabled={product.max_qty === product.quantity} onClick={() => addToCart(product)}>
                          <div className="custom-plus"></div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <td>{product.name}</td>
                  <td>${product.price.toFixed(2)}</td>
                  <td>{product.quantity}</td>
                  <td>${(product.price * product.quantity).toFixed(2)}</td>
                  <td>
                    <button onClick={() => handleRemove(product.id, product.quantity)}>Remove</button>
                  </td> */}
              </div>
            ))}
          </div>
          {console.log(products, "products")}
          {products.length > 0 &&
            <div className="bill-details py-2">
              {console.log(products[0], "view cart.............")}
              <Products id={products[0].id} products_for="Before you checkout" filter_id={products[0].subcategory} insideCart={true} />
            </div>
          }
          <div className="bill-details f_12_500 f_roboto py-2">
            <div className="bill-head">
              Bill details
            </div>
            <div className="rowBetweenCenter mb-2">
              <div className="rowCenter">
                <BsFileTextFill size={14} style={{ marginRight: "6px" }} /> Item total
                {productAmount.discount + productAmount.PackingCharges > 0 &&
                  <div className="saved">
                    saved ₹{parseFloat(productAmount.discount).toFixed(2)}
                  </div>
                }
              </div>
              {productAmount.discount > 0 ?
                <div>
                  <strike>₹{parseFloat(productAmount.mrp).toFixed(2)}</strike>
                  <span> ₹{parseFloat(productAmount.mrp - productAmount.discount).toFixed(2)}</span>
                </div>
                :
                <div>₹{parseFloat(productAmount.mrp).toFixed(2)}</div>
              }
            </div>
            {console.log(orderAddress, "inside view cart")}
            {orderAddress &&
              <>
                {

                  <div className="rowBetweenCenter">
                    <div className="rowCenter">
                      <RiMotorbikeFill size={14} style={{ marginRight: "6px" }} /> Delivery charges
                    </div>
                    <div>
                      {productAmount.delivery_waived ? <div>
                        <strike>₹{parseFloat(productAmount.delivery_waived).toFixed(2)}</strike> <blue> waiver</blue>
                      </div> :
                        <div>
                          ₹{parseFloat(productAmount.deliveryCharges).toFixed(2)}
                        </div>
                      }
                    </div>
                  </div>
                }
                < div className="rowBetweenCenter">
                  <div className="rowCenter">
                    <MdOutlineShoppingBag size={14} style={{ marginRight: "6px" }} /> Packing charges
                  </div>
                  <div>
                    {productAmount.packing_waived ? <div>
                      <strike>₹{parseFloat(productAmount.packing_waived).toFixed(2)}</strike> <blue> waiver</blue>
                    </div> :
                      <div>
                        ₹{parseFloat(productAmount.PackingCharges).toFixed(2)}
                      </div>
                    }
                  </div>
                </div>
              </>
            }
            {Order.spike_comment && JSON.parse(localStorage.getItem("site_data")).ecommerce_type !== "Conventional" &&
              <div className="spike-comment">
                {Order.spike_comment}
              </div>
            }
            <div className="grand-total">
              <div className="f_13_700">
                Grand total
              </div>
              <div className="grand-total">
                ₹{orderAddress ? parseFloat(productAmount.totalAmount).toFixed(2) : parseFloat(productAmount.mrp).toFixed(2)}
                {console.log(productAmount, "view cart.............")}
              </div>
            </div>
            {/* <div className="cuppons-aply">
              <CiDiscount1 size={20} className="me-2" />
              Coupons are only applicable on the Opoto app
            </div> */}
          </div>
          <div className="bill-details">
            <div className="CancellationPolicy">
              Cancellation Policy
            </div>
            <div className="CancellationPolicy-content">
              Orders cannot be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.
            </div>
          </div>
        </>
      )
      }
    </div >
  );
}
export { ViewCart };