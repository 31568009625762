import React, { useState, useEffect } from "react";
import "./Products.css"
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import axiosConfig from "../../Service/axiosConfig"
import { addProduct, removeProduct } from "../../redux/Slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { MdAdd, MdRemove } from "react-icons/md";
import { AccessDeny, LocationModal } from "../PopupModal";
import { NavLink, useParams } from "react-router-dom";
import { BreadCrumbs } from "../BreadCrums";
import WishlistIcon from "../Wishlist";
import { Products } from "../Products";
import { AiFillCaretRight } from "react-icons/ai";
import axios from "axios";
import ProductMultipleImages from "./ProductMultipeImages";
import { TiTick } from "react-icons/ti";

const ProductDetail = (props) => {
    let { Setlogin, LoginAccount } = props
    console.log(props, "inside product detail...")
    const { cart } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [LocationpopupType, setLocationpopupType] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [brandName, setBrandName] = useState()
    const [shopdeals, setShopDeals] = useState(null)
    const [Image, setImage] = useState(null)
    let site_data = JSON.parse(localStorage.getItem("site_data"))
    const Handlepopup = () => {
        setLocationpopupType("")
    }

    const addToCart1 = () => {
        const siteData = JSON.parse(localStorage.getItem("site_data"));
        if (siteData.ecommerce_type !== "Conventional") {
            if (localStorage.getItem("storeId")) {
                dispatch(
                    addProduct({
                        product: {
                            id: item.id,
                            name: item.name,
                            image: item.image,
                            price: item.price,
                            offer_price: item.offer_price,
                            weight_in_kg: item.weight_in_kg,
                            max_qty: item.max_qty,
                            subcategory: item.subcategory,
                            speed: item.category
                        },
                        quantity: 1
                    })
                );
            }
            localStorage.getItem("storeId") ? Handlepopup() : setLocationpopupType("success")
        }
        else {
            dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty, subcategory: item.subcategory, speed: item.category }, quantity: 1 }));
        }
    }


    const addToCart = () => {
        dispatch(addProduct({
            product: {
                id: item.id,
                name: item.name,
                image: item.image,
                price: item.price,
                weight_in_kg: item.weight_in_kg,
                max_qty: item.max_qty,
                subcategory: item.subcategory,
                speed: item.category
            }, quantity: 1
        }));
        console.log("added")
    };

    const removeFromCart = () => {
        dispatch(removeProduct({ productId: item.id, quantity: 1 }));
        console.log("Item removed from your cart")
    };

    const { item, setIsDeleted } = props;
    console.log(props.all_props)

    const getSimilarProducts = async () => {
        try {
            const response = await axiosConfig.get(`/catlog/products/?category=&brand=${item.brand}&price=&discount=&is_suspended=false`)
            console.log(response.data, "filtered brand")
        } catch (error) {
            console.log(error)
        }
    }

    const getProductQuantity = (productId) => {
        const product = cart.products.find((p) => p.id === productId);
        return product ? product.quantity : 0;
    };
    console.log(cart)

    const getBrandName = async (id) => {
        try {
            const response = await axiosConfig.get(`/catlog/brands/${id}/`)
            console.log(response.data.brand_name, "brandname...")
            setBrandName(response.data.brand_name)
        } catch (error) {
            console.log(error, "Error getting brand name")
        }
    }

    const GetShopDeals = async () => {
        try {
            const response = await axiosConfig.get(`/cms/shopdeals/`)
            setShopDeals(response.data.results[0])
        } catch (error) {
            console.log("Error getting shop deals", error)
        }
    }

    useEffect(() => {
        item.brand && getBrandName(item.brand);
        !shopdeals && GetShopDeals();
    }, [brandName])

    useEffect(() => {
        if (item == 900) {
            // Create og:title meta tag
            const ogTitleMeta = document.createElement('meta');
            ogTitleMeta.setAttribute('property', 'og:title');
            ogTitleMeta.setAttribute('content', item.name);
            document.head.appendChild(ogTitleMeta);
            const existingTitle = document.querySelector('title');
            if (existingTitle) {
                existingTitle.innerText = item.meta_title; // Update the existing title with the new one
            } else {
                const titleTag = document.createElement('title');
                titleTag.innerText = item.meta_title;
                document.head.appendChild(titleTag);
            }

            // Create og:description meta tag
            const ogDescriptionMeta = document.createElement('meta');
            ogDescriptionMeta.setAttribute('property', 'og:description');
            ogDescriptionMeta.setAttribute('content', item.meta_description);
            document.head.appendChild(ogDescriptionMeta);

            // Create description meta tag
            const Description = document.createElement('meta');
            Description.setAttribute('property', 'description');
            Description.setAttribute('content', item.meta_description);
            document.head.appendChild(Description);


            // Create keyword meta tag
            const Keyword = document.createElement('meta');
            Keyword.setAttribute('property', 'keyword');
            Keyword.setAttribute('content', item.meta_keyword);
            document.head.appendChild(Keyword);



            // Create og:keyword meta tag
            const ogKeywordMeta = document.createElement('meta');
            ogKeywordMeta.setAttribute('property', 'og:keyword');
            ogKeywordMeta.setAttribute('content', item.meta_keyword);
            document.head.appendChild(ogKeywordMeta);

            // Create og:image meta tag
            const ogImageMeta = document.createElement('meta');
            ogImageMeta.setAttribute('property', 'og:image');
            ogImageMeta.setAttribute('content', item.image?.image);
            document.head.appendChild(ogImageMeta);

            // Cleanup function to remove dynamically added meta tags when component unmounts
            return () => {
                document.head.removeChild(ogTitleMeta);
                document.head.removeChild(ogDescriptionMeta);
                document.head.removeChild(ogKeywordMeta);
                document.head.removeChild(ogImageMeta);
                document.head.removeChild(Description);
                document.head.removeChild(Keyword);
            }
        }
        setImage(item?.image?.image)
    }, [item]);

    return (
        <div className="product-detail">
            {/* <Helmet>
                <meta name="description" content={item.meta_description} />
                {console.log(item.meta_description)}
                <meta name="keyword" content={item.meta_keyword} />
          </Helmet> */}
            <div className="pdfl">
                <div className="pdfl-left">
                    <div className="product-detail-img">
                        <img src={Image} alt={item?.name} />
                    </div>
                    <div>
                        {item && <ProductMultipleImages id={item.id} Image={Image} setImage={setImage} />}
                    </div>
                    <hr className="horizontal-line" />
                    <div className="product-description">
                        <p className="desc-title">Product Details</p>
                        {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                        {/* <p className="desc-details"> {item.description} </p> */}
                    </div>
                </div>
                <div className="pdfl-right">
                    <div className="product-detail-info">
                        <BreadCrumbs
                            active_page={props.item.name}
                            sub_page={props.all_props.categoryurl}
                            prev_sub_page={props.all_props.subcategoryurl}
                        />
                        <h1 className="p-title">{item.name}</h1>
                        {brandName &&
                            <div className="all-by-brand">
                                {`View all by ${brandName}`}
                                <AiFillCaretRight size={12} />
                            </div>
                        }
                        <hr />
                        <div className="product-detail-actions">
                            <div className="row">
                                <div className="col-8">
                                    <div className="p-price">
                                        {/* <p className="p-weight">{item.weight} {item.measurement_unit}</p> */}
                                        <div className="d-flex gap_price">
                                            {item.offer_price && item.offer_price > 0 ? (
                                                <>
                                                    <p className="price me-1">₹{(parseFloat(item.offer_price)).toFixed(0)}</p>
                                                    <p className={item.offer_price ? "produc-price me-1" : "price me-1"}>₹{parseFloat(item.price).toFixed(0)}</p>
                                                    <div className="offer-price">{item.offer_price ? `${((item.price - item.offer_price) / item.price * 100).toFixed(1)}% off` : " "}</div>
                                                </>
                                            ) : (
                                                <p className="price">₹{parseFloat(item.price)}</p>
                                            )}
                                        </div>
                                    </div>


                                </div>
                                <div className="col-4">
                                    <div className="product-actions">
                                        <div className="p-btn">
                                            {console.log(cart.products)}
                                            {cart.products.some((p) => p.id === item.id) ? (
                                                <div className="btn btn-cart btn-cart-added">
                                                    <button className="btn-minus" onClick={removeFromCart}>
                                                        <div className="custom-minus"></div>
                                                    </button>
                                                    <div className="qty">{getProductQuantity(item.id)}</div>
                                                    <button className="btn-minus" disabled={item.max_qty === getProductQuantity(item.id)} onClick={addToCart}>
                                                        <div className="custom-plus"></div>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-cart" onClick={addToCart1}>Add</button>
                                            )}
                                        </div>
                                        <div>
                                            <WishlistIcon productId={item.id} setIsDeleted={setIsDeleted} LoginAccount={LoginAccount} Setlogin={Setlogin} />
                                        </div>
                                        {
                                            LocationpopupType === "success" &&
                                            <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} />
                                        }
                                        {
                                            NoAccess === "success" &&
                                            <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} />
                                        }
                                    </div>
                                </div>
                                <div className="product-description-mobile">
                                    <p className="desc-title">Product Details</p>
                                    {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                                </div>
                                <div className="why-us">
                                    <p className="why-title">{shopdeals?.main_title}</p>
                                    <div className="why-cards">
                                        {(shopdeals?.subtitle_1 || shopdeals?.description1) &&
                                            !(shopdeals?.subtitle_1 === "undefined" || shopdeals?.description1 === "undefined") && (
                                                <div className="row align-items-center">
                                                    <div className="col-2 d-flex justify-content-center">
                                                        {shopdeals?.image1 ? <img src={shopdeals?.image1} alt="Deal Image" /> : <TiTick size={20} />}
                                                    </div>
                                                    <div className="col-10">
                                                        <p className="why-bold-text">{shopdeals?.subtitle_1}</p>
                                                        <p className="why-text-color">{shopdeals?.description1}</p>
                                                    </div>
                                                </div>
                                            )}

                                        {(shopdeals?.subtitle_2 || shopdeals?.description2) &&
                                            !(shopdeals?.subtitle_2 === "undefined" || shopdeals?.description2 === "undefined") &&
                                            <div className="row align-items-center">
                                                <div className="col-2 d-flex justify-content-center">
                                                    {shopdeals?.image2 ? <img src={shopdeals?.image2} /> : <TiTick size={20} />}
                                                </div>
                                                <div className="col-10">
                                                    <p className="why-bold-text">{shopdeals?.subtitle_2}</p>
                                                    <p className="why-text-color">{shopdeals?.description2}</p>
                                                </div>
                                            </div>
                                        }
                                        {(shopdeals?.subtitle_3 || shopdeals?.description3) &&
                                            !(shopdeals?.subtitle_3 === "undefined" || shopdeals?.description3 === "undefined") &&
                                            <div className="row align-items-center">
                                                <div className="col-2 d-flex justify-content-center">
                                                    {shopdeals?.image3 ? <img src={shopdeals?.image3} /> : <TiTick size={20} />}
                                                </div>
                                                <div className="col-10">
                                                    <p className="why-bold-text">{shopdeals?.subtitle_3}</p>
                                                    <p className="why-text-color">{shopdeals?.description3}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Products id={item.id} products_for="Similar Products" filter_id={item.subcategory[0]} />

        </div>
    )
}
export { ProductDetail };