import React, { useState, useEffect } from "react";
import "./Products.css"
import axiosConfig from "../../Service/axiosConfig"
import { json, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { addProduct, removeProduct } from "../../redux/Slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { MdAdd, MdRemove } from "react-icons/md";
import { TfiTimer } from "react-icons/tfi"
import { NavLink, useParams } from "react-router-dom";
import { AccessDeny, LocationModal } from "../PopupModal";
import { selectCartTotal } from "../../redux/Slices/cartSlice";
import WishlistIcon from "../Wishlist";
const Product = (props) => {
    let user = localStorage.getItem("userId")
    let { friendlyurl } = useParams;
    const { cart } = useSelector((state) => state);
    const [isWishlist, setIsWishlist] = useState(false);
    const [popupType, setPopupType] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [LocationpopupType, setLocationpopupType] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const total = useSelector(selectCartTotal);
    const [charges, setCharges] = useState({
        delivery_charges: 0,
        packing_charges: 0
    })
    const Navigate = useNavigate()
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const dispatch = useDispatch();
    let Data = []
    const Handlepopup = () => {
        setLocationpopupType("")
    }

    const addToCart = () => {
        dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty, subcategory: item.subcategory, speed: item.category }, quantity: 1 }));
    };
    const addToCart1 = () => {
        const siteData = JSON.parse(localStorage.getItem("site_data"));
        if (siteData.ecommerce_type !== "Conventional") {
        if (localStorage.getItem("storeId")) {
            dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty, subcategory: item.subcategory, speed: item.category }, quantity: 1 }));
        }
        localStorage.getItem("storeId") ? Handlepopup() : setLocationpopupType("success")
    }
    else{
        dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty, subcategory: item.subcategory, speed: item.category }, quantity: 1 }));  
    }
    };
    const removeFromCart = () => {
        dispatch(removeProduct({ productId: item.id, quantity: 1 }));
        console.log("Item removed from your cart")
    };
    const { item, setIsDeleted } = props;
    const { LoginAccount, Setlogin } = props
    const serializedState = JSON.stringify(cart.products);
    // localStorage.setItem('state', serializedState);
    const getProductQuantity = (productId) => {
        const product = cart.products.find((p) => p.id === productId)
        return product ? product.quantity : 0;
    };
    const HandleNavigate = (path) => {
        console.log(path, friendlyurl)
        Navigate(path)
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])
    console.log(item)
    return (
        <>
            <div className="product-item">
                <div className="product-wish-icon">
                    {item.available &&
                        <WishlistIcon productId={item.id} setIsDeleted={setIsDeleted} LoginAccount={LoginAccount} Setlogin={Setlogin} />
                    }
                </div>
                {item.discount_percentage > 0 && (
                    <div class="discount_icon_container">
                        <div class="discount_percentage">{item.discount_percentage}% OFF</div>
                    </div>
                )}
                {item.available ? (
                    // <NavLink to={friendlyurl ? `${friendlyurl}/${item.slug}` : `/${item.slug}`}>
                    <div className="product-img" onClick={() => HandleNavigate(friendlyurl ? `${friendlyurl}/${item.slug}` : `/${item.slug}`)}>
                        <img src={item.image ? item.image.image : item.image} alt={item?.name} />
                        {!item.available && <div className="product-overlay">out of stock</div>}
                    </div>
                    // </NavLink>
                ) : (
                    <div className="product-img">
                        <img src={item.image ? item.image.image : item.image} alt={item?.name} />
                        {!item.available && <div className="product-overlay">out of stock</div>}
                    </div>
                )}
                {localStorage.getItem("site_data") && JSON.parse(localStorage.getItem("site_data")).ecommerce_type !== "Conventional" && localStorage.getItem("express_delivery_time") && (
                    <div className="timer">
                        <TfiTimer size={10} />
                        <span>{localStorage.getItem("express_delivery_time")} mins</span>
                    </div>
                )}
                {item.available ? (
                    // <NavLink to={friendlyurl ? `${friendlyurl}/${item.slug}` : `/${item.slug}`}>
                    <p className="name" onClick={() => HandleNavigate(friendlyurl ? `${friendlyurl}/${item.slug}` : `/${item.slug}`)}>{item.name}</p>
                    // </NavLink>
                ) : (
                    <p className="name">{item.name}</p>
                )}
                {/* <div className="product-weight">
                    {item.weight} {item.measurement_unit}
                </div> */}
                <div className="product-actions">
                    <div className="p-price">
                        {item.offer_price && item.offer_price > 0 ? (
                            <>
                                <p className="price">₹{item.offer_price}</p>
                                <p className={item.offer_price ? "produc-price" : "price"}>₹{item.price}</p>
                            </>
                        ) :
                            <p className="price">₹{item.price}</p>}

                    </div>
                    <div className="p-btn">{console.log(cart.products)}
                        {cart.products.some((p) => p.id === item.id) ? (
                            <div className="btn btn-cart btn-cart-added">
                                <button className="btn-minus" onClick={removeFromCart}>
                                    <div className="custom-minus"></div>
                                </button>

                                <div className="qty">{getProductQuantity(item.id)}</div>
                                <button className="btn-minus" disabled={item.max_qty === getProductQuantity(item.id)} onClick={addToCart}>
                                    <div className="custom-plus"></div>
                                </button>
                            </div>
                        ) : (
                            item.available ?
                                <button className="btn btn-cart" onClick={addToCart1} >Add</button>
                                :
                                <></>
                        )}
                    </div>
                    {
                        LocationpopupType === "success" &&
                        <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} />
                    }
                    {
                        NoAccess === "success" &&
                        <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} />
                    }
                </div>
            </div>
        </>
    )
}
export default Product;