import React, { useState, useEffect } from "react";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axiosConfig from "../../Service/axiosConfig";
import { PopupModal } from "../../Components";


const ContuctUs = () => {
  
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const site_data = JSON.parse(localStorage.getItem("site_data"));
    const [popupType, setPopupType] = useState("");
    const [message, setMessage] = useState("");
    const [locations, setLocations] = useState([]);
    const [Error, SetError] = useState("");

    const { isLoaded } = useJsApiLoader({
        id: 'AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ',
        googleMapsApiKey: "AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
    })
   
    

    const GetStores = async () => {
        try {
            const store_response = await axiosConfig.get(`stores/stores/`)
            if (store_response.data.results.length > 0) {
                setLocations(store_response.data.results.map((item) => ({ lat: item.latitude, lng: item.longitude })))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async(event) => {
        event.preventDefault(); 
        const formData = new FormData(event.target); 
        const formObject = {};
        let mobile = localStorage.getItem("number");
        formData.forEach((value, key) => {
            formObject[key] = value;
        });
        formObject.mobile = mobile;
        formObject.service = "Customer Support";
        formObject.source = "Customer Support Page";
        try{
            const response = await axiosConfig.post(`/blogapp/leads/`, formObject)
            setPopupType("success");
            setMessage("Your query has been submitted")
            event.target.reset();
        }catch(error){
            console.log("error submitting the query : ", error)
            SetError("Error submitting the query, please try again later")
        }
    };

    useEffect(() => {
        GetStores()
    }, [])
    
    return (
        <>
       
            <Navbar />
            <div className="container pt-3">
                <div className="row">
                    <div className="col-6">
                        <h5>{site_data.copy_right && JSON.parse(site_data.copy_right)?.website} Branches</h5>
                        <div style={{ height: '300px', width: '100%' }}>
                            {isLoaded &&
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{ lat: 17.4375, lng: 78.4482 }}
                                    zoom={10}
                                >
                                    {locations.map((item, index) => <Marker key={index} position={item} />)}
                                </GoogleMap>}
                        </div>
                    </div>

                    <div className="col-6">
                        <form id="contactForm" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Your Name:</label>
                                <input id="name" name="name" className="form-control" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">E-Mail Address *:</label>
                                <input id="email" name="email" type="email" className="form-control" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="query">Enquiry *:</label>
                                <textarea id="query" name="query" className="form-control" required />
                            </div>
                            <button type="submit" className="btn btn-secondary btn-block">Submit</button>
                        </form>
                    </div>
                </div>

            </div>
            {popupType != "" &&
                <PopupModal popupType={popupType} setPopupType={setPopupType} error={Error} Message={message} />
            }
            <Footer />
        </>
    )
}
export { ContuctUs }