import React, { useState, useRef, useEffect } from "react";
import "./Products.css";
import axiosConfig from "../../Service/axiosConfig";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function SampleNextArrow(props) {
    const { className, style, onClick, insideCart } = props;
    return (
        <div className="next-arrow">
            <div
                className={className}
                style={{
                    ...style, display: "none", right: insideCart ? "-10px" : "-42px"
                }}
                onClick={onClick}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, insideCart } = props;
    return (
        <div className="previous-arrow">
            <div
                className={className}
                style={{
                    ...style, display: "none", left: "-10px",
                }}
                onClick={onClick}
            />
        </div>
    );
}

const ProductMultipleImages = (props) => {
    const { insideCart } = props;
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([]);
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };

    const previous = () => {
        sliderRef.slickPrev();
    };

    const GetSimilarProducts = async (id) => {
        try {
            const response = await axiosConfig.get(`/catlog/product-images/?product=${id}`);
            SetData(response.data.results);
        } catch (error) {
            console.log("Error getting similar products : ", error);
        }
    };

    useEffect(() => {
        if (props.id) {
            GetSimilarProducts(props.id);
        }
    }, [props.id]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: insideCart ? 2 : 4,
        slidesToScroll: insideCart ? 2 : 4,
        nextArrow: <SampleNextArrow insideCart={insideCart} />,
        prevArrow: <SamplePrevArrow insideCart={insideCart} />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: insideCart ? 2 : 5,
                    slidesToScroll: insideCart ? 2 : 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: insideCart ? 2 : 4,
                    slidesToScroll: insideCart ? 2 : 4,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: insideCart ? 2 : 3,
                    slidesToScroll: insideCart ? 2 : 3,
                },
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const imageStyle = {
        width: "80%",
        height: "80%",
        objectFit: "cover", // Ensures the image fits within the container
    };

    const containerStyle = {
        width: "88px",
        height: "66px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        marginLeft: "35px",
        transition: "border 0.3s ease", // Smooth transition for border color
    };

    return (
        <div className="products-container">
            {loading ? (
                <div className="loader-container">
                    <img src={require("../images/loader.gif")} alt="Loading..." />
                </div>
            ) : (
                Data.length > 0 && (
                    <div className="" style={{ maxWidth: "463px" }}>
                        <div className="slider-container">
                            {Data.length >= 4 ? (
                                <Slider
                                    ref={(slider) => {
                                        sliderRef = slider;
                                    }}
                                    {...settings}
                                    style={{ width: '100%', marginLeft: "35px" }}
                                >
                                    {Data.map((itemObj, index) => {
                                        const borderColor = props.Image === itemObj?.image?.image ? '#0C831F' : '#CCC';
                                        return (
                                            <div key={index} onClick={() => props.setImage(itemObj.image?.image)}>
                                                <div style={{ ...containerStyle, border: `1px solid ${borderColor}` }}>
                                                    <img src={itemObj?.image?.image} alt={itemObj?.Product?.name} style={imageStyle} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            ) : Data.length >= 2 && insideCart ? (
                                <Slider
                                    ref={(slider) => {
                                        sliderRef = slider;
                                    }}
                                    {...settings}
                                >
                                    {Data.map((itemObj, index) => {
                                        const borderColor = props.Image === itemObj?.image?.image ? '#0C831F' : '#CCC';
                                        return (
                                            <div key={index} onClick={() => props.setImage(itemObj.image?.image)}>
                                                <div style={{ ...containerStyle, border: `1px solid ${borderColor}` }}>
                                                    <img src={itemObj?.image?.image} alt={itemObj?.Product?.name} style={imageStyle} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            ) : Data.length === 1 ? (
                                Data.map((itemObj, index) => {
                                    const borderColor = props.Image === itemObj?.image?.image ? '#0C831F' : '#CCC';
                                    return (
                                        <div key={index} onClick={() => props.setImage(itemObj.image?.image)}>
                                            <div style={{ ...containerStyle, border: `1px solid ${borderColor}` }}>
                                                <img src={itemObj?.image?.image} alt={itemObj?.Product?.name} style={imageStyle} />
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="row">
                                    {Data.map((itemObj, index) => {
                                        const borderColor = props.Image === itemObj?.image?.image ? '#0C831F' : '#CCC';
                                        return (
                                            <div className="col-3" key={index} onClick={() => props.setImage(itemObj.image?.image)}>
                                                <div style={{ ...containerStyle, border: `1px solid ${borderColor}` }}>
                                                    <img src={itemObj?.image?.image} alt={itemObj?.Product?.name} style={imageStyle} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {Data.length > 4 && (
                                <>
                                    <div className="left"  style={{right:"-36px"}}><MdChevronLeft size={30} onClick={previous} /></div>
                                    <div className="right" style={{right:"-71px"}}><MdChevronRight size={30} onClick={next} /></div>
                                </>
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default ProductMultipleImages;
