import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import {
  DashboardLayout,
  Navbar,
  ProductDetail,
  ProductsList,
  SubCategories,
  Footer,
} from "../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BreadCrumbs } from "../Components/BreadCrums";
import {
  MdShoppingCart,
  MdOutlineHome,
  MdSearch,
  MdOutlineAddCircleOutline,
  MdWorkOutline,
  MdOtherHouses,
} from "react-icons/md";
import { ViewCart } from "../Components/Navbar/ViewCart";
import { Login } from "../Components/Login/Login";
import { selectCartTotal, selectCartProducts } from "../redux/Slices/cartSlice";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { BiLeftArrowAlt } from "react-icons/bi";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { AiOutlineDown } from "react-icons/ai";
import "./Pages.css";
import { LoginModal } from "../Components/LoginModal/LoginModal";
import ViewCartModal from "../Components/Navbar/ViewCartModal";

import { Helmet } from "react-helmet";

const ProductDetailPage = (props) => {
  const { product_data } = props;

  const { cart } = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [Totalamount, setTotalAmount] = useState({
    Total_amount: 0,
  });

  const [Login, Setlogin] = useState(false);
  const total = useSelector(selectCartTotal);
  const products = useSelector(selectCartProducts);
  const [count, setCount] = useState(1);
  const [orderAddress, setOrderAddress] = useState();
  const [Error, SetError] = useState("");
  const [NoStock, setNoStock] = useState([]);
  const [storeNull, setStoreNull] = useState(false);
  let ProductData = [];
  const [Order, setOrders] = useState({
    address: "",
    products: [],
    stores: localStorage.getItem("stores"),
    user_id: localStorage.getItem("userId"),
    packing_charges: 0,
    delivery_charges: 0,
  });
  const [smShow, setSmShow] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [Address, setAddress] = useState([]);
  const [loginShow, setloginShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const handleLoginShow = () => setloginShow(true);
  const handleLoginClose = () => setloginShow(false);
  console.log(props.friendlyurl);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  let Navigate = useNavigate();
  function getBaseUrl(domainName) {
    const hostname = window.location.hostname.replace("www.", "");
    const protocol = window.location.protocol;
    const portnumber = window.location.port ? `:${window.location.port}` : "";
    return `${protocol}//${hostname}${portnumber}`;
  }
  function insertCanonicalLink() {
    let baseUrl = getBaseUrl();
    let canonicalUrl = `${baseUrl}/${product_data.slug}`;
    let existingCanonical = document.querySelector('link[rel="canonical"]');

    if (!existingCanonical) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `
                <link rel="canonical" href="${canonicalUrl}">
            `
      );
    }
  }

  insertCanonicalLink();

  const HandleNavigate = () => {
    Navigate("/");
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };
  const ProductMRP = async (data) => {
    console.log(data);
    try {
      let totalwt = {
        stores: JSON.parse(localStorage.getItem("storeId")),
        products: [],
      };
      let ProductData = []; // Initialize ProductData array

      data.forEach((item) => {
        totalwt.products.push({
          id: item.id,
          min_qty: item.quantity,
        });

        ProductData.push({
          id: item.id,
          min_qty: item.quantity,
        });
      });

      axiosConfig.defaults.headers["Content-Type"] = "application/json";
      const charges_response = await axiosConfig.post(
        `accounts/get_delivery_packing_charges/`,
        totalwt
      );
      console.log(charges_response.data, "charges");

      let delivery_charges = charges_response.data
        .schedule_waier_delivery_charges
        ? 0
        : charges_response.data.schedule_delivery_charges;
      let packing_charges = charges_response.data.schedule_waier_packing_charges
        ? 0
        : charges_response.data.schedule_packing_charges;

      setTotalAmount({
        Total_amount: total + delivery_charges + packing_charges,
      });

      setOrders({
        ...Order,
        packing_charges: packing_charges,
        delivery_charges: delivery_charges,
        stores: localStorage.getItem("storeId"),
        user_id: parseFloat(localStorage.getItem("userId")),
        address: localStorage.getItem("address_id"),
        delivery_type: "Schedule",
        products: ProductData,
      });
    } catch (error) {
      console.log(
        "Error:",
        error.response.data.error ||
        "Delivery charges not found for the given parameters"
      );
    }
  };

  const GetAddress = async (id) => {
    try {
      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Conventional"
      ) {
        return null;
      }
      const response = await axiosConfig.get(`/accounts/address/${id}/`);
      const store_response = await axiosConfig.get(
        `/stores/get_store/?latitude=${response.data.latitude}&longitude=${response.data.longitude}`
      );
      if (
        store_response.data.Message.store_id !== null &&
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Hyperlocal"
      ) {
        localStorage.setItem(
          "online",
          store_response.data.Message.is_online === "offline" ? true : false
        );
        localStorage.setItem("storeId", store_response.data.Message.store_id);
        localStorage.setItem("opens_at", store_response.data.Message.opens_at);
        localStorage.setItem(
          "closes_at",
          store_response.data.Message.closes_at
        );
        localStorage.setItem(
          "slot_duration",
          store_response.data.Message.slot_duration
        );
        // window.location.reload();
        setOrderAddress(response.data);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("address_id", response.data.id);
        localStorage.setItem("address", response.data.address);
        localStorage.setItem("latitude", response.data.latitude);
        localStorage.setItem("longitude", response.data.longitude);
        if (store_response.data.Message.is_online === "offline") {
          localStorage.setItem("express_delivery", false);
          localStorage.removeItem("express_delivery_time");
        } else {
          localStorage.setItem(
            "express_delivery",
            store_response.data.Message.express_delivery
          );
          if (
            store_response.data.Message.express_delivery &&
            store_response.data.Message.express_delivery_time
          ) {
            localStorage.setItem(
              "express_delivery_time",
              store_response.data.Message.express_delivery_time
            );
          } else {
            localStorage.removeItem("express_delivery_time");
          }
        }
        cart.products.forEach((res) => {
          console.log(res);
          ProductData.push({
            id: res.id,
            min_qty: res.quantity,
          });
        });
        setOrders({
          ...Order,
          stores: localStorage.getItem("storeId"),
          user_id: parseFloat(localStorage.getItem("userId")),
          address: id,
          products: ProductData,
        });
        setStoreNull(false);
      } else if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Conventional"
      ) {
        setOrderAddress(response.data);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("address_id", response.data.id);
        localStorage.setItem("address", response.data.address);
        localStorage.setItem("latitude", response.data.latitude);
        localStorage.setItem("longitude", response.data.longitude);
        cart.products.forEach((res) => {
          console.log(res);
          ProductData.push({
            id: res.id,
            min_qty: res.quantity,
          });
        });
        setOrders({
          ...Order,
          stores: localStorage.getItem("storeId"),
          user_id: parseFloat(localStorage.getItem("userId")),
          address: id,
          products: ProductData,
        });
      } else {
        setStoreNull(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const AddressLocal = async (id) => {
    try {
      const response = await axiosConfig.get(
        `/accounts/address/${localStorage.getItem("address_id")}/`
      );
      setOrderAddress(response.data);
      cart.products.forEach((res) => {
        console.log(res);
        ProductData.push({
          id: res.id,
          min_qty: res.quantity,
        });
      });
      setOrders({
        ...Order,
        stores: localStorage.getItem("storeId"),
        user_id: parseFloat(localStorage.getItem("userId")),
        address: localStorage.getItem("address_id"),
        products: ProductData,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const ChangeAddress = () => {
    setCount(2);
    setOrderAddress();
  };
  const GetTheAddress = () => {
    setCount(2);
    GetAddressData();
  };
  const GetAddressData = async () => {
    try {
      const response = await axiosConfig.get(
        `/accounts/address/?user=${localStorage.getItem(
          "userId"
        )}&is_suspended=false`
      );
      setAddress(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  const PayMentNavigate = async () => {
    let order_obj = Order;
    let request_type = "post";
    let url = "/accounts/orders/";
    if (localStorage.getItem("orderId")) {
      order_obj["order_id"] = localStorage.getItem("orderId");
      request_type = "put";
      url = `/accounts/orders/${localStorage.getItem("orderId")}/`;
    }
    try {
      axiosConfig.defaults.headers["Content-Type"] = "application/json";
      const res = await axiosConfig[request_type](url, Order);
      localStorage.setItem("orderId", res.data.id);
      Navigate("/checkout");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.message) {
        setSmShow(true);
        setErrorMsg(error.response.data.message);
      }
      if (
        error.response &&
        error.response.data[
        "The following products are not available in the store:"
        ]
      ) {
        error.response.data[
          "The following products are not available in the store:"
        ].forEach(async (res) => {
          try {
            const productRes = await axiosConfig.get(
              `/catlog/products/${res.id}/?is_suspended=false`
            );
            setNoStock((prestate) => [...prestate, productRes.data]);
            setSmShow(true);
          } catch (error) {
            console.error(error);
          }
        });
      }
    }
  };
  const MovetoCart = () => {
    setCount(1);
    setOrderAddress();
    setStoreNull(false);
  };
  const HandleAddress = () => {
    setPopupType("success");
  };
  const HndleSmallShow = () => {
    setSmShow(false);
    setNoStock("");
    setErrorMsg(null);
  };
  useEffect(() => {
    AddressLocal();
    GetAddressData();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function formatOpeningTime(openingTime) {
    // Assuming openingTime is in 24-hour format HH:mm
    const [hours, minutes] = openingTime.split(":");
    const formattedHours = parseInt(hours) % 12 || 12;
    const amOrPm = parseInt(hours) >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }
  return (
    <>
      {isMobile ? (
        <div className="navBar-mobile-main">
          <header className="nav-mobile-header">
            <div className="nav-mobile-common">
              <div className="navmbf1" onClick={HandleNavigate}>
                <BiLeftArrowAlt className="leftarroworder" />
              </div>
              <div className="navmbf2">
                <div className="nav-mobile-time">
                  {JSON.parse(localStorage.getItem("site_data"))
                    ?.ecommerce_type === "Hyperlocal"
                    ? localStorage.getItem("express_delivery_time")
                      ? `Delivery in ${localStorage.getItem(
                        "express_delivery_time"
                      )} minutes`
                      : "Delivering to"
                    : "Delivering to"}
                </div>
               {JSON.parse(localStorage.getItem("site_data"))
                    ?.ecommerce_type === "Hyperlocal"&& <div className="nav-mobile-store">
                  {localStorage.getItem("address")}
                </div>}
              </div>
              <div className="navmbf3">
                <NavLink to="/s">
                  <MdSearch className="search-mdicon" />
                </NavLink>
              </div>
            </div>
          </header>
          {cart.products.length > 0 && (
            <div
              className="btn btn-success cart-container show-tablet"
              onClick={handleShow}
            >
              <div className="cart-icon-holder">
                <MdShoppingCart />
              </div>
              <div className="cart-qty">
                {cart.products.length > 0 ? (
                  <p>{cart.products.length} items</p>
                ) : (
                  <p>My Cart</p>
                )}
              </div>
              <span className="p-price">
                ₹
                {parseFloat(
                  Totalamount.Total_amount -
                  Order.packing_charges -
                  Order.delivery_charges
                )}
              </span>
            </div>
          )}
          <ViewCartModal
            count={count}
            setCount={setCount}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            orderAddress={orderAddress}
            setOrderAddress={setOrderAddress}
            setOrders={setOrders}
            Order={Order}
            ProductData={ProductData}
            setTotalAmount={setTotalAmount}
            products={products}
            ChangeAddress={ChangeAddress}
            GetTheAddress={GetTheAddress}
            PayMentNavigate={PayMentNavigate}
            handleLoginShow={handleLoginShow}
            cart={cart}
            Totalamount={Totalamount}
            isMobile={isMobile}
            MovetoCart={MovetoCart}
            HandleAddress={HandleAddress}
            storeNull={storeNull}
            Address={Address}
            GetAddress={GetAddress}
            travelTime={localStorage.getItem("express_delivery_time")}
          />
          <Modal
            size="sm"
            show={smShow}
            onHide={HndleSmallShow}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
          >
            <Modal.Body>
              {NoStock.length >= 1 ? (
                NoStock.map((res, i) => {
                  console.log(res);
                  return (
                    <div key={i} className="p-avalibile">
                      {res.name}
                      <br />
                    </div>
                  );
                })
              ) : (
                <div className="p-avalibile">{NoStock.name}</div>
              )}
              {!errorMsg && (
                <div className="not-avalible text-center">
                  Not Available in Selected Store
                </div>
              )}
              {errorMsg && <div className="min-value-error">{errorMsg}</div>}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={HndleSmallShow}>
                Ok
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <>
          <Navbar LoginAccount={Login} />
        </>
      )}
      <div className="container">
        <ProductDetail
          item={product_data}
          all_props={props}
          Setlogin={Setlogin}
          LoginAccount={Login}
        />
      </div>
      {loginShow && (
        <LoginModal setLoginType={setloginShow} LoginType={loginShow} />
      )}
      <Footer />
    </>
  );
};
export { ProductDetailPage };
