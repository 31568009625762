import React, { useEffect, useState } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { MdOutlineEditLocation, MdDownloadForOffline, MdOutlineLocalPhone } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser, AiOutlineArrowLeft } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig";
import { BiRupee } from "react-icons/bi";
import "./MyOrderDetails.css";
import { current } from "@reduxjs/toolkit";

const MyOrderDetails = () => {
  const [loading, setLoading] = useState()
  const [Orders, setOrders] = useState([])
  const [OrdersData, setOrdersData] = useState({})
  const [Address, setAddress] = useState({})
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [couponDiscount, setCouponDiscount] = useState(0)
  const [deliveryCharges, setDeliveryCharges] = useState()
  const [deliveryChargesWaived, setDeliveryChargesWaived] = useState()
  const [packingCharges, setPackingCharges] = useState()
  const [packingChargesWaived, setPackingChargesWaived] = useState()
  const [discountTotals, setDiscountTotals] = useState(null)

  let Navigate = useNavigate()
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };
  const [OrderDate, setOrderDate] = useState({
    dayOfWeek: "",
    dayOfMonth: "",
    month: "",
    year: "",
    hours: "",
    minutes: "",
    ampm: ""
  })
  const [OrderMRP, setOrderMRP] = useState({
    mrp: 0,
    discount: 0,
    totalAmount: 0,
    percentage: 0
  })
  const [ActiveUrl, setActiveUrl] = useState("")
  let { orderId, address } = useParams()
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  function calculateDiscountTotals(couponApplied) {
    return couponApplied.reduce((totals, item) => {
      if (item.discount_type === "BogoDiscount") {
        totals.bogo_discount += parseFloat(item.coupon_discount);
      } else if (item.discount_type === "DiscountCoupon") {
        totals.product_discount += parseFloat(item.coupon_discount);
      } else if (item.discount_type === "FreeOrDiscountedItemWithPurchase") {
        totals.free_item += parseFloat(item.coupon_discount);
      }
      return totals;
    }, { bogo_discount: 0, product_discount: 0, free_item: 0 });
  }
  const GetData = async () => {
    setLoading(true)
    try {
      const response = await axiosConfig.get(`/accounts/order-details/?order=${orderId}&page_size=1000`)
      setOrders(response.data.results)
      // ProductMRP(response.data.results)
      // GetEditdata()
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const GetOrders = async () => {
    const response = await axiosConfig.get(`/accounts/orders/${orderId}/`)
    console.log(response)
    setOrdersData(response.data)
    setCouponDiscount(response.data?.coupon_code)
    setDeliveryCharges(parseFloat(response.data.delivery_charges))
    setDeliveryChargesWaived(parseFloat(response.data.delivery_charges_offer))
    setPackingCharges(parseFloat(response.data.packing_charges))
    setPackingChargesWaived(parseFloat(response.data.packing_charges_offer))
    const dateString = response.data.order_date
    const date = new Date(dateString);
    console.log(response.data.expected_date_time, JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional", dateString, date)
    const offsetMinutes = 330;
    let totals = calculateDiscountTotals(response.data?.coupon_code);
    setDiscountTotals(totals)
    // Print the adjusted minutes
    console.log("minutes", date.getUTCMinutes() + offsetMinutes);

    // Adjust the date by adding the offset
    date.setMinutes(date.getMinutes() + offsetMinutes);

    // Arrays for weekdays and months
    const weekdaysIST = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsIST = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Get IST values
    const dayOfWeek = weekdaysIST[date.getUTCDay()];
    const dayOfMonth = String(date.getUTCDate()).padStart(2, '0');
    const month = monthsIST[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour1 = hours % 12;
    hours = hour1 || 12; // Ensure that 12-hour format is used correctly
    const formattedHours = String(hours).padStart(2, '0');
    setOrderDate({
      dayOfWeek: dayOfWeek,
      dayOfMonth: dayOfMonth,
      month: month,
      year: year,
      hours: formattedHours,
      minutes: minutes,
      ampm: ampm
    })
  }
  // const ProductMRP = async (data) => {
  //   console.log(data)
  //   let Total = data && data.reduce(function (prev, current) {
  //     const Price = parseFloat(current.product.price)
  //     return prev + +Price * current.quantity
  //   }, 0)
  //   let sum = data && data.reduce(function (prev, current) {
  //     const updatedItem = { ...current };
  //     const offerPrice = parseFloat(current.product.offer_price);
  //     const price = parseFloat(current.product.price);
  //     if (offerPrice === 0 || !offerPrice) {
  //       updatedItem.product.price = price;
  //       return prev + +updatedItem.product.price * updatedItem.quantity;
  //     } else if (offerPrice > 0) {
  //       updatedItem.product.price = offerPrice;
  //       return prev + +updatedItem.product.price * updatedItem.quantity;
  //     }
  //     return prev
  //   }, 0);
  //   let percent = Total - sum
  //   setOrderMRP({
  //     mrp: Total,
  //     discount: Total - sum,
  //     totalAmount: sum,
  //     percentage: percent / Total * 100
  //   })
  // }

  const handleDownload = () => {
    if (OrdersData.invoice?.pdf_path) {
      window.open(OrdersData.invoice.pdf_path, "_blank");
    } else {
      alert('Invoice is not available')
    }
  }
  const GetEditdata = async () => {
    try {
      const response = await axiosConfig.get(`/accounts/address/${address}/`)
      console.log(response)
      setAddress(response.data)
    }
    catch (error) {
      console.log(error)
    }
  }
  const timeConvert = (date) => {
    const givenDateTime = new Date(date);
    let hours = givenDateTime.getHours();
    const minutes = givenDateTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours %= 12;
    hours = hours || 12; // If hours is 0, set it to 12

    const newTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return newTime;
  }

  const timeString = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleDateString('en-US', options);
  }
  useEffect(() => {
    GetOrders()
    GetEditdata()
    GetData();
    GetlocaData()
    // ProductMRP(Orders)
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  return (
    <>
      <Navbar />
      {
        isMobile ?
          <div className="container-fluid my-profile_mobile">
            <div className="row">
              <div className="col-12 col-sm-12 order-overflow">
                <NavLink to="/account/orders" className="back-to-orders"><AiOutlineArrowLeft /></NavLink>
                <div className="order-content">
                  <div className="order-status-mobile">
                    {OrdersData.orderstatus === "Delivered" ?
                      "Yay! Order successfully delivered"
                      :
                      "Yay! Order successfully placed"
                    }
                  </div>
                  <p>Placed on {OrderDate.dayOfWeek}, {OrderDate.dayOfMonth} {OrderDate.month} {OrderDate.year},{OrderDate.hours + ":" + OrderDate.minutes}{OrderDate.ampm}</p>
                </div>
                <div className="rowCenter justify-content-center">
                  <div>
                    <p className="f_20_600">Payment method</p>
                    <p className="f_15_400">{OrdersData.payment_mode === "cash" ? "Cash on delivery" : "Online payment"}</p>
                  </div>
                  <div style={{ width: "117px", height: "117px" }}>
                    <img src={require("../Pages/images/pay-mode.png")} alt="mode" style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>

                <div className="order-summary">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="total-items">{Orders.length} items</h5>
                      <div className="rowCenter gap-10 mb-4">
                        <div className="order-id">Order Id:{OrdersData?.id}</div>
                        <MdDownloadForOffline className="pointer download-icon" size={30} onClick={handleDownload} />
                      </div>
                    </div>
                    {
                      Orders && Orders.map((order, i) => {
                        return (
                          <div key={i} >
                            <div className="d-flex justify-content-between">
                              <div className="d-flex gap-5">
                                <img src={order.product.image?.image} alt="" className="order-item-image" />
                                <div>
                                  <p>{order.product.name}</p>
                                  <p>{order.product.weight_in_kg}</p>
                                  <p>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}*{order.quantity}</p>
                                </div>
                              </div>
                              {/* <span>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) * order.quantity : "₹" + parseFloat(order.product.price) * order.quantity}</span> */}
                              <span>₹{Math.min(parseFloat(order.pre_discount_amount), parseFloat(order.total_amount))}</span>
                            </div>
                            <div className="discount-comment">{order.discount_comment}</div>
                            <hr />
                          </div>
                        )
                      })
                    }
                  </div>

                </div>

                <div className="order-bill-details">
                  <div className="p-3">
                    <h5>Bill Details</h5>
                    <div className="rowBetweenCenter">
                      <div className="rowCenter">
                        <span>Item total</span>
                        {OrdersData.discount_amount > 0 &&
                          <span className="saved">
                            {/* saved ₹{(parseFloat(OrdersData.discount_amount) + parseFloat(discountTotals?.product_discount)).toFixed(2)} */}
                            saved ₹{parseFloat(OrdersData.discount_amount)}
                          </span>
                        }
                      </div>
                      <div>
                        {OrdersData.discount_amount > 0 ?
                          <div>
                            <strike>₹{(parseFloat(OrdersData.total_amount) + parseFloat(OrdersData.discount_amount)).toFixed(2)}</strike>
                            <span className="ms-2">₹{
                              // (
                              (OrdersData.total_amount)
                              // - 
                              // parseFloat(OrdersData.discount_amount) + 
                              // parseFloat(discountTotals?.product_discount || 0) + 
                              // parseFloat(discountTotals?.bogo_discount || 0)
                              // ).toFixed(2)
                            }
                            </span>
                          </div>
                          :
                          <span>₹{parseFloat(OrdersData.total_amount).toFixed(2)}</span>
                        }
                      </div>
                    </div>
                    {discountTotals?.bogo_discount > 0 &&
                      <div className="rowBetweenCenter">
                        <div>Buy 1 Get 1 discount</div>
                        <div>-₹{parseFloat(discountTotals.bogo_discount)}</div>
                      </div>
                    }
                    {discountTotals?.product_discount > 0 &&
                      <div className="rowBetweenCenter">
                        {console.log("discountTotals", discountTotals)}
                        <div>Coupon discount</div>
                        <div>-₹{parseFloat(discountTotals.product_discount).toFixed(2)}</div>
                      </div>
                    }
                    <div className="rowBetweenCenter">
                      <span>Delivery charges</span>
                      <div>
                        {deliveryCharges !== 0 ?
                          <span>₹{deliveryCharges}</span>
                          :
                          <div className="rowCenter gap-10">
                            <strike>₹{deliveryChargesWaived}</strike>
                            <blue>waiver</blue>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="rowBetweenCenter">
                      <span>Packing charges</span>
                      <div>
                        {packingCharges !== 0 ?
                          <span>₹{packingCharges}</span>
                          :
                          <div className="rowCenter gap-10">
                            <strike>₹{packingChargesWaived}</strike>
                            <blue>waiver</blue>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="rowBetweenCenter">
                      <h6>Grand total</h6>
                      {/* <p>₹{(parseFloat(OrdersData.net_amount) - parseFloat(discountTotals?.free_item || 0 )).toFixed(2)}</p> */}
                      <p>₹{parseFloat(OrdersData.net_amount)}</p>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between px-3 mb-5 order-saving">
                        <p className="mb-0">your savings</p>
                        <p className="mb-0">
                          {(parseFloat(OrdersData.discount_amount) * 100 / parseFloat(OrdersData.total_amount)).toFixed(0)}%</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pay-mode f_roboto">
                  <div className="p-3">
                    <div className="rowCenter gap-10">
                      <img src={require("../Pages/images/door_delivery.png")} alt="door delivery" style={{ width: "30px", height: "30px" }} />
                      <div className="f_20_600">Delivery Address</div>
                    </div>
                    {Address.name && <div className="f_13_700 mt-2">Name: <span className="f_13_400">{Address.name}</span></div>}
                    {Address.user?.mobileno && <div className="f_13_700">Phone number: <span className="f_13_400">{Address.user?.mobileno}</span></div>}
                    {JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional"?<div className="f_13_700">Address: <span className="f_13_400">{`${Address.address ? Address.address : ""}, ${Address.landmark ? Address.landmark : ""}, ${Address.pincode ? Address.pincode : ""}`}</span></div>:<div className="f_13_700">Address: <span className="f_13_400">{`${Address?.flat_no},,${Address.address ? Address.address : ""}, ${Address.landmark ? Address.landmark : ""}, ${Address.pincode ? Address.pincode : ""}`}</span></div>}
                  </div>
                  <div className="bg-img-ord-detail">
                    <div className="f_13_500">Delivery Type: <span className="f_13_400">{OrdersData.delivery_type}</span></div>
                    {OrdersData.delivery_type === "Express" ?
                      <div className="f_13_500">Expected Delivery Time: <span className="f_13_400">{timeString(OrdersData.expected_date_time)}</span></div>
                      :
                      <div className="f_13_500">Scheduled at: <span className="f_13_400">{timeString(OrdersData.expected_date_time).split(',').slice(0, -1).join(', ')} {OrdersData.schedule_slot}</span></div>
                    }
                  </div>
                </div>

                <div className="customer-support">
                  <MdOutlineLocalPhone className="me-3" />
                  <span>Customer support - {JSON.parse(localStorage.getItem("site_data"))?.mobileno}</span>
                </div>

              </div>
            </div>
          </div> :
          <div className="container my-profile_rn">
            <div className="row">
              <div className="col-lg-2 col-md-2 profile-main">
                <div className="profile-nav__details-block">
                  <div class="account-profile__img"></div>
                  <div class="account-profile__phone">+91{localStorage.getItem("number")}</div>
                </div>
                <nav className="profile-nav__list">
                  <ul className="list-unstyled">
                    <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
                    <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
                    {/* <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink> */}
                    <NavLink to="/logout" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-10 col-md-10 order-overflow">
                <NavLink to="/account/orders" className="back-to-orders"><AiOutlineArrowLeft /></NavLink>
                <div className="order-content">
                  <span className="order-status">
                    {
                      OrdersData.orderstatus === "Delivered"
                        ? "Yay! Order successfully delivered"
                        : OrdersData.orderstatus === "Rejected"
                          ? "Yay! Order Cancelled"
                          : "Yay! Order successfully placed"
                    }
                  </span>
                  <p className="f_13_400">Placed on {OrderDate.dayOfWeek}, {OrderDate.dayOfMonth} {OrderDate.month} {OrderDate.year},{OrderDate.hours + ":" + OrderDate.minutes}{OrderDate.ampm}</p>
                </div>
                <div className="rowCenter justify-content-center">
                  <div>
                    <p className="f_25_600">Payment method</p>
                    <p className="f_20_400">{OrdersData.payment_mode === "cash" ? "Cash on delivery" : "Online payment"}</p>
                  </div>
                  <div style={{ width: "117px", height: "117px" }}>
                    <img src={require("../Pages/images/pay-mode.png")} alt="mode" style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>

                <div className="order-summary">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="total-items">{Orders.length} items</h5>
                      <div className="rowCenter gap-10 mb-4">
                        <div className="order-id">Order Id:{OrdersData?.id}</div>
                        <MdDownloadForOffline className="pointer download-icon" size={30} onClick={handleDownload} />
                      </div>
                    </div>
                    {
                      Orders && Orders.map((order, i) => {
                        return (
                          <div key={i} >
                            <div className="d-flex justify-content-between">
                              <div className="d-flex gap-5">
                                <img src={order.product.image?.image} alt="" className="order-item-image" />
                                <div>
                                  <p>{order.product.name}</p>
                                  <p>{order.product.weight_in_kg}</p>
                                  <p>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}*{order.quantity}</p>
                                </div>
                              </div>
                              {/* <span>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) * order.quantity : "₹" + parseFloat(order.product.price) * order.quantity}</span> */}
                              <span>₹{Math.min(parseFloat(order.pre_discount_amount), parseFloat(order.total_amount))}</span>
                            </div>
                            <div className="discount-comment">{order.discount_comment}</div>
                            <hr />
                          </div>
                        )
                      })
                    }
                  </div>

                </div>

                <div className="order-bill-details">
                  <div className="p-3">
                    <h5>Bill Details</h5>
                    <div className="rowBetweenCenter">
                      <div className="rowCenter">
                        <span>Item Total</span>
                        {OrdersData.discount_amount > 0 &&
                          <span className="saved">
                            {/* saved ₹{(parseFloat(OrdersData.discount_amount) + parseFloat(discountTotals?.product_discount)).toFixed(2)} */}
                            saved ₹{parseFloat(OrdersData.discount_amount)}
                          </span>
                        }
                      </div>
                      <div>
                        {OrdersData.discount_amount > 0 ?
                          <div>
                            <strike>₹{(parseFloat(OrdersData.total_amount) + parseFloat(OrdersData.discount_amount)).toFixed(2)}</strike>
                            <span className="ms-2">₹{
                              // (
                              (OrdersData.total_amount)
                              // -
                              // parseFloat(OrdersData.discount_amount) + 
                              // parseFloat(discountTotals?.product_discount || 0) + 
                              // parseFloat(discountTotals?.bogo_discount || 0)
                              // ).toFixed(2)
                            }</span>
                          </div>
                          :
                          <span>₹{parseFloat(OrdersData.total_amount).toFixed(2)}</span>
                        }
                      </div>
                    </div>
                    {discountTotals?.bogo_discount > 0 &&
                      <div className="rowBetweenCenter">
                        <div>Buy 1 Get 1 discount</div>
                        <div>-₹{parseFloat(discountTotals.bogo_discount).toFixed(2)}</div>
                      </div>
                    }
                    {discountTotals?.product_discount > 0 &&
                      <div className="rowBetweenCenter">
                        {console.log("discountTotals", discountTotals)}
                        <div>Coupon discount</div>
                        <div>-₹{parseFloat(discountTotals.product_discount).toFixed(2)}</div>
                      </div>
                    }
                    <div className="rowBetweenCenter">
                      <span>Delivery charges</span>
                      <div>
                        {deliveryCharges !== 0 ?
                          <span>₹{deliveryCharges}</span>
                          :
                          <div className="rowCenter gap-10">
                            <strike>₹{deliveryChargesWaived}</strike>
                            <blue>waiver</blue>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="rowBetweenCenter">
                      <span>Packing charges</span>
                      <div>
                        {packingCharges !== 0 ?
                          <span>₹{packingCharges}</span>
                          :
                          <div className="rowCenter gap-10">
                            <strike>₹{packingChargesWaived}</strike>
                            <blue>waiver</blue>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="rowBetweenCenter">
                      <h6>Grand total</h6>
                      {/* <p>₹{(parseFloat(OrdersData.net_amount) - parseFloat(discountTotals?.free_item || 0)).toFixed(2)}</p> */}
                      <p>₹{parseFloat(OrdersData.net_amount)}</p>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between px-3 mb-5 order-saving">
                        <p className="mb-0">your savings</p>
                        <p className="mb-0">{(parseFloat(OrdersData.discount_amount) * 100 / parseFloat(OrdersData.total_amount)).toFixed(0)}%</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pay-mode f_roboto">
                  <div className="p-3">
                    <div className="rowCenter gap-10">
                      <img src={require("../Pages/images/door_delivery.png")} alt="door delivery" style={{ width: "30px", height: "30px" }} />
                      <div className="f_20_600">Delivery Address</div>
                    </div>
                    <div className="f_13_700 mt-2">Name: <span className="f_13_400">{Address.name}</span></div>
                    <div className="f_13_700">Phone number: <span className="f_13_400">{Address.user?.mobileno}</span></div>
                    <div className="f_13_700">Address : <span className="f_13_400">
                      {`${Address.flat_no ? `${Address.flat_no}, ` : ''}${Address.address ? `${Address.address}, ` : ''}`}
                    </span>
                      {/* <span className="f_13_400">{`${Address.flat_no && Address.flat_no}, ${Address.address && Address.address}, ${Address.landmark && Address.landmark}, ${Address.pincode && Address.pincode}`}</span> */}
                    </div>
                  </div>
                  <div className="bg-img-ord-detail">
                    <div className="f_18_500">Delivery Type: <span className="f_18_400">{OrdersData.delivery_type}</span></div>
                    {OrdersData.delivery_type === "Express" ?
                      <div className="f_18_500">Expected Delivery Time: <span className="f_18_400">{timeString(OrdersData.expected_date_time)}</span></div>
                      :
                      <div className="f_18_500">Scheduled at: <span className="f_18_400">{timeString(OrdersData.expected_date_time).split(',').slice(0, -1).join(', ')} {OrdersData.schedule_slot}</span></div>
                    }
                  </div>
                </div>

                <div className="customer-support">
                  <MdOutlineLocalPhone className="me-3" />
                  <span>Customer support - {JSON.parse(localStorage.getItem("site_data"))?.mobileno}</span>
                </div>

              </div>
            </div>
          </div>
      }
    </>
  )
}
export { MyOrderDetails }