import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";


const HomeMeta = ({siteData}) => {
    let dataLayer;
    useEffect(() => {
        if (siteData.googleanalytics_id ) {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=G-${siteData.googleanalytics_id}`;
            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', `G-${siteData.googleanalytics_id}`);
            };

            document.head.appendChild(script);
            window[`ga-disable-G-${siteData.googleanalytics_id}`] = true;
        }
    }, [siteData.googleanalytics_id]);
    useEffect(() => {
        if (siteData.googleanalytics_id && !window.gaDisableInitialLoad) {
            const script = document.createElement('script');
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-${siteData.googleanalytics_id}');
                window.gaDisableInitialLoad = true;
            `;
            
            document.head.appendChild(script);
        }
    }, [siteData.googleanalytics_id]);
   return (
        <>
            <Helmet>
                <title>{siteData.title}</title>
                <meta property="og:title" content={siteData.title} />
                <meta name="description" content={siteData.meta_description} />
                <meta name="og:description" content={siteData.meta_description} />
                <meta property="keywords" content={siteData.keywords} />
                <meta property="og:keywords" content={siteData.keywords} />
                {siteData.favicon && 
                    <link rel="icon" href={siteData.favicon.image} type="image/x-icon" />
                }
                {siteData.logo && 
                    <meta name="og:image" property="og:image" content={siteData.logo.image} />
                }
</Helmet>
 </>
 )
}
export { HomeMeta };