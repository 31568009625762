import React, { useState, useEffect } from "react";
import { Navbar } from "..";
import { Footer } from "..";
import axiosConfig from "../../Service/axiosConfig";

const TermsAndServices = () => {
   const [formData, setFormData] = useState({});
   const site_data = JSON.parse(localStorage.getItem("site_data"));
   const GetPolicies = async () => {
      try {
         const responce = await axiosConfig.get(`/catlog/policies/`);
         console.log(responce.data.results)
         setFormData(responce.data.results[0])
      } catch (error) {
         console.log(error)
      }
   }

   useEffect(() => {
      GetPolicies()
   }, [])

   useEffect(()=>{
      if(site_data)
        {
          // Create description meta tag
          const Description = document.createElement('meta');
          Description.setAttribute('property', 'description');
          Description.setAttribute('content', site_data.meta_description);
          {console.log(site_data.meta_description)}
          document.head.appendChild(Description);
          return()=>{
          document.head.removeChild(Description);
          }
      }
    },[])
    useEffect(()=>{
      window.scrollTo(0, 0);
    })

   return (
      <>
         <Navbar />
         <div className="container pt-3">
            {formData.terms_conditions && <div dangerouslySetInnerHTML={{ __html: formData.terms_conditions }} />}
         </div>
         {/* <div className="container pt-3">
            <h4>TERMS & CONDITIONS</h4>
            <p><b>Opoto.in</b>, its website, mobile applications, sub-domains, its services are operated and owned by M/s MAS Associates, Hyderabad, Telangana here referred to as <b>“Opoto or Website or We or Us”</b>.</p>
            <b>Users</b>
            <p>All users who visit, access, use the website, mobile applications, affiliates, all sub-domains in any form:</p>
            <p>Here referred to as <b>“Users or You or Your”</b>.</p>
            <p>IF YOU ARE USING OPOTO, THESE TERMS ARE APPLICABLE AND YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS OF USAGE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, PLEASE DO NOT USE THIS PLATFORM.</p>
            <b>NATURE AND APPLICABILITY OF TERMS</b>
            <p>
               This document is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries guidelines) 2011, that provides for the due diligence to be exercised for the access or usage of this Website.
            </p>
            <b>Use Of Demographic / Profile Data / Your Information</b>
            <p>
               We use the collected personal information through the website or any form of sources to extend our best services, offers, and promotions to you. We may provide the Personal Information to our partners and/or affiliates who work on behalf of or with Opoto. In such circumstances, you consent to us disclosing your Personal Information to such parties and contractors, solely for the intended purposes only.
               With your consent, we will have access to your SMS, contacts in your directory, location, and device information to enhance your experience on the platform and provide you access to the services being offered by us, our affiliates, or partners. Access, storage, and use of this data will be in consonance with applicable laws. You should understand that your access to these services may be affected in the event when the consent is withdrawn.
               In our efforts to continually improve service offerings, we and our affiliates collect and analyze demographic and profile data about our users' activity on our Website.
            </p>
            <p>These terms and conditions of usage apply to access and use of Opoto and its services in any form. These terms of use constitutes a legal and binding contract between You and Opoto.</p>
            <p>By accessing and/or availing any services of Opoto, you signify your agreement to be bound by these Terms and Conditions. Further, by impliedly or expressly accepting these Terms and Conditions, you also accept and agree to be bound by our policies, including the Privacy Policy, disclaimers and such other rules, guidelines, policies, as are relevant under the applicable law(s) in India for the purposes of accessing, browsing or transacting on the Website, or availing any of the Services, shall be deemed to be incorporated into, and considered as part and parcel of these Terms and conditions.</p>
            <p>Opoto reserves the right at any time, at our sole discretion, to change or otherwise modify the Terms and Conditions without prior notice. You should read the Agreement at regular intervals. Your use of the Website following any such modification constitutes your agreement to follow and be bound by the Agreement so modified.</p>
            <b>GENERAL ELIGIBILITY TO USAGE OF SERVICES</b>
            <ul>
               <li>Your age should be 18 years and above;</li>
               <li>All the personal information and other details you submit to use Opoto services should be correct and accurate;</li>
               <li>All the services by Opoto limited for the users exclusively for their personal usage and not for any third party usage or commercial practices.</li>
               <li>You are not eligible to reuse or modify any content, images, logos including but not limited to all copyright and trademark properties of Opoto unless you have a permission from Opoto in written to reuse or modify;</li>
               <li>You are not eligible and not permitted to decompile, reverse engineer or disassemble Opoto;</li>
               <li>You are not eligible and not permitted to access or use Opoto in any form which may harmful to the Opoto’s, its partners’, affiliates’ Proprietary Software and Operations;</li>
               <li>You are not eligible and not permitted to upload, submit, write, post, distribute any software computer files which contain any virus or other harmful components or otherwise impair or damage Opoto, its Partners and Affiliates;</li>
               <li>You expressly understand and agree that to access and/use the Services or Information provided by Opoto on an “as is” and “as available” basis only. Opoto, its partners,    affiliates, agents,         employees, and all other associates disclaim all warranties of any kind, either express or implied, including but not limited to, implied warranties on       merchantability, fitness for a particular purpose and non-infringement.</li>
            </ul>

            <h4>USAGE TERMS OF REGISTERED USERS, SUBSCRIPTIONS AND OTHER SERVICES:</h4>

            <b>User Account, Registration and Bookings</b>
            <p>All users have to register and login for placing orders on the website or app or through call center. Users have to keep user account and registration details recent and correct for all communications related to all purchases from us. By agreeing to the terms and conditions, the user agrees to receive promotional communication and newsletters upon registration. The customer can opt out either by unsubscribing in "User Account" or by contacting the customer service.</p>
            <p>All the Users can book the products through Website, Mobile App and through call centre also.</p>
            <b>Pricing</b>
            <p>
               All the products listed on the website will be sold at MRP unless otherwise specified. The prices mentioned at the time of ordering will be remains same on the date of the delivery. Although prices of most of the products do not fluctuate on a daily basis but some of the commodities and fresh food prices do change on a daily basis. In case the prices are higher or lower on the date of delivery Opoto will not collect additional charges or refunded as the case may be at the time of the delivery of the order.
            </p>
            <b>SUBSCRIPTIONS:</b>
            <p>
               To serve & facilitate the USERS better, Opoto has designed annual subscriptions with below benefits and with terms:
            </p>
            <p>
               <b>Dedicated Relationship Manager</b>: Opoto will assuring the USERS “Dedicated Relationship Manager” support to through selected subscription plans. With this facility the USERS can book the orders over phone at their convenient time. The specified timings for booking the orders through Dedicated Relationship Manager over phone during operating hours only and the users should understand and respect the queues for the service.
            </p>
            <b>Dedicated Relationship Manager:</b>
            <p>
               Opoto will assure the USERS “Dedicated Relationship Manager” support through selected subscription plans.
               With this facility, the USERS can book orders over the phone at their convenient time. The specified timings for booking the orders through the Dedicated Relationship Manager over the phone are during operating hours only, and the users should understand and respect the queues for the service.
            </p>

            <b>Loyalty Cash Rewards:</b>
            <p>
               Opoto will assure the USERS “Loyalty Cash Rewards” scheme also through selected subscription plans which can be redeemed through the orders during checkouts as per the terms and conditions of the valid subscription plan. These rewards are exclusively for the usage of the subscribed user on specified orders. These rewards cannot transfer to any other users’ accounts or any other third-party sites.
            </p>

            <b>Delivery Charges Waiver:</b>
            <p>
               Opoto will also assure the USERS “Delivery Charges Waiver” scheme through selected subscription plans which is strictly applicable for the subscribed users and for the orders worth as specified in the subscription plan only.
            </p>

            <b>Exclusive Special Discount Offers:</b>
            <p>
               Opoto will assure the USERS “Exclusive Special Discount Offers” scheme other than the regular discount schemes also through selected subscription plans which are announced and published time to time and applicable for the decided period and on available products during the scheme period.
            </p>

            <b>BOOKING & SHIPPING POLICY</b>
            <p>
               <b>Bookings:</b>
            </p>
            <p>
               Opoto has arranged all necessary arrangements to exhibit the products range in categories available for users to shop at their desired locations where Opoto delivery is available. All Opoto Users are eligible to book all products which are in stock. All Opoto users can also add all unavailable products or desired ones for future bookings to Wish List.
            </p>

            <b>Minimum & Maximum Order Value and Quantities of Products:</b>
            <p>
               In an attempt to standardize the logistics system, Opoto allows a minimum and maximum order value (single order cart) for all orders as well as minimum and maximum quantities for a few products. All Opoto users are eligible to book the orders accordingly.
            </p>

            <b>Shipping:</b>
            <p>
               Opoto is committed and has arranged all necessary arrangements to deliver the orders at accepted locations/pin codes within 12-48 hours from the booking time for all in-stock products with predefine delivery charges.
            </p>

            <p>
               Common Delivery Timings: Opoto has arranged necessary arrangements to deliver the orders between 7 am & 10 am every day for all regular bookings, and Opoto may accept deliveries during other than mentioned timings on special requests/orders with extra delivery charges.
            </p>

            <b>CANCELLATION & REFUND POLICY</b>
            <h3>Cancellations:</h3>
            <p>
               All Users can cancel their order anytime up to the cut-off time of the slot for which users have placed an order by calling our customer service. In such a case, we will refund any payments already made by you for the order. If we suspect any fraudulent transaction by any customer or any transaction which defies the terms & conditions of using the website, we at our sole discretion could cancel such orders. We will maintain a negative list of all fraudulent transactions and customers and would deny access to them or cancel any orders placed by them.
            </p>

            <b>Return and Refund Policy:</b>
            <p>
               All our Users can return any product at the time of delivery if, due to some reason, they are not satisfied with the quality or freshness of the product.
            </p>
            <p>
               For all the cancelled and returned orders, we will process and arrange the refund in the original mode of payment which will be credited within 7 – 10 working days.
            </p>
            <p>
               Under no circumstances, cash will be refunded against any cancellation for which the payments made in Digital Formats.
            </p>

            <b>NOT FOR EMERGENCY USE</b>
            <p>
               Users acknowledge and agree that the Services are neither intended nor suitable for urgent emergency use, and that you will not use it for emergency purposes.
            </p>

            <b>DISCLAIMER OF WARRANTIES</b>
            <p>
               To the fullest extent permissible by law, OPOTO does not warrant that the Website or any of the Services or Products available through it will be uninterrupted or free from errors. There may be delays, omissions, interruptions and inaccuracies in materials or Services available through the Website or that the Services will meet any particular criteria of performance or quality.
            </p>

            <p>
               OPOTO makes no representation or warranties about the accuracy, completeness, timeliness, reliability, or non-infringement of any content on the Website or Services available through it.
            </p>
            <p>
               Although OPOTO intends to take reasonable steps to prevent the introduction of viruses, worms or other malicious code to the Website, OPOTO does not represent or warrant that the Website, or Services, features or materials that may be available through the Website, are free from such destructive features. OPOTO is not liable for any damages or harm attributable to such features.</p>
            <p>
               EXCEPT AS EXPRESSLY SET FORTH HEREIN, THIS WEBSITE AND THE SERVICES ARE PROVIDED BY OPOTO ON AN "AS IS" AND "AS AVAILABLE" BASIS. OPOTO MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE WEBSITE, THE SERVICES OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS WEBSITE. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, OPOTO DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE TITLE, COMPATIBILITY, SECURITY, ACCURACY, OR NON-INFRINGEMENT WITHOUT LIMITING THE FOREGOING, OPOTO DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, FOR ANY MERCHANDISE OFFERED ON THIS WEBSITE. USER ACKNOWLEDGE, BY USER’S USE OF THE OPOTO’ WEBSITE, THAT USER’S USE OF THE WEBSITE IS AT USER’S SOLE RISK. THIS DISCLAIMER DOES NOT APPLY TO ANY PRODUCT WARRANTY OFFERED BY THE MANUFACTURER OF THE PRODUCT. THIS DISCLAIMER CONSTITUTES AN ESSENTIAL PART OF THESE TERMS.
            </p>
            <p>
               PRODUCT INFORMATION ACCESSED THROUGH THIS WEBSITE IS OBTAINED FROM CLAIMS MADE BY THE PRODUCT'S MANUFACTURER/SUPPLIER. AT TIMES, MANUFACTURERS/SUPPLIERS MAY ALTER THEIR LABELS SO ACTUAL PRODUCT PACKAGING AND MATERIALS MAY CONTAIN DIFFERENT INFORMATION THAN THAT SHOWN ON OPOTO WEBSITE, THUS OPOTO CANNOT GUARANTEE OR ENSURE THE ACCURACY, COMPLETENESS OR TIMELINESS OF ANY PRODUCT INFORMATION. WE RECOMMEND THAT USER DO NOT SOLELY RELY ON THE INFORMATION PRESENTED AND THAT USER ALWAYS READ LABELS, WARNINGS, AND DIRECTIONS BEFORE USING OR CONSUMING A PRODUCT. FOR ADDITIONAL INFORMATION ABOUT A PRODUCT, PLEASE CONTACT THE MANUFACTURER/SUPPLIER. OPOTO ASSUMES NO LIABILITY FOR INACCURACIES OR MISSTATEMENTS ABOUT PRODUCTS. CUSTOMER REVIEWS ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY. CUSTOMER REVIEWS REFLECT THE INDIVIDUAL REVIEWER'S RESULTS AND EXPERIENCES ONLY AND ARE NOT VERIFIED OR ENDORSED BY OPOTO.
            </p>

            <b>LIMITATION OF LIABILITY AND INDEMNITY</b>
            <p>Nothing in these Terms is intended to affect user’s rights under law. The use of the Website or any of the Services or Products is at user’s own risk, and unless otherwise stated in these Terms, user assumes full responsibility and risk of loss resulting from user’s use of the Website or any of the Services or Products.</p>
            <p>By using the Website, user agrees, to the fullest extent permitted by law, to indemnify and hold OPOTO, its directors, officers, employees, affiliates, group companies, agents, contractors, and licensors harmless with respect to any claims arising out of user’s breach of these Terms, user’s use of the Website or any of the Services, user’s non-compliance with applicable laws or regulations in the jurisdiction in which users are accessing the Website or the Services, or any action taken by OPOTO as part of its investigation of a suspected violation of these Terms or as a result of its finding or decision that a violation of these Terms has occurred. User agrees to defend, indemnify and hold harmless OPOTO and its affiliates and group companies from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to user’s use of the Website or any breach by user of these Terms.</p>
            <p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE, SHALL OPOTO OR ANY OF ITS AFFILIATES, GROUP COMPANIES, EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, VENDORS OR SUPPLIERS BE LIABLE TO USER OR TO ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, BUSINESS INTERRUPTION, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF AN AUTHORISED REPRESENTATIVE OF OPOTO HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH (I) THE USE OF OR INABILITY TO USE THE OPOTO WEBSITE www.opotoo.in; (II) USER’S USE OF OR RELIANCE ON THE WEBSITE, ANY INFORMATION, HYPERLINKS OR CONTENT CONTAINED THEREIN, OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO USERTHROUGH THIS WEBSITE; (III) USER’S PROVISION OF INFORMATION, PERSONAL OR OTHERWISE, TO OPOTO; (IV) THE PROVISION OF SERVICES BY OPOTO; OR (V) ACTS OR NEGLIGENCE ON THE PART OF OPOTO. IN NO EVENT WILL OPOTO BE LIABLE FOR ANY LOSSES, DAMAGES OR EXPENSES IN EXCESS OF THE AMOUNT PAID BY USER TOWARDS THE PRICE OF THE PRODUCT IN RESPECT TO WHICH THE CLAIM AROSE. USER HEREBY RELEASES US FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS IN EXCESS OF THIS LIMITATION.</p>
            <b>Governing Law; Dispute Resolution:</b>
            <p>This Agreement shall be governed by, and construed in accordance with the laws of India, without regard to the principles of conflict of laws of any other jurisdiction. The courts of Hyderabad, India shall have the exclusive jurisdiction, for remedies available at law to such party.</p>
            <b>Declaration:</b>
            <p>This is to confirm that we have understood the contents of Opoto’s Terms and Conditions for USERS and the same has been explained and understood by I/us. We agree and undertake that in performance of my/our obligations under this agreement with Opoto, I/we shall including but not limited to our relatives, friends, employees, agents, consultants or representatives, etc., shall be required to adhere to the requirements of this acknowledgement.</p>
            <b>Grievance Officer:</b>
            <p>In case of any queries or assistance regarding the Privacy Policy you can contact our grievance officer, provided below the name and contact details of the Grievance Officer:
               Santosh Pothamshetty<br />
               Opoto.in (A unit of M/s MAS Associates)<br />
               # 202, Suraj Mansion, Lane Opposite to Lal Bunglaw,<br />
               Leelanagar, Ameerpet,<br />
               Hyderabad, Telangana Pin -500016.<br />
               Email: support@Opoto.in<br />
               Phone: +91 9618114770.<br />
               Time: Mon-Sat (9:00 - 18:00)
            </p>
         </div> */}
         <Footer />
      </>
   )
}
export { TermsAndServices }