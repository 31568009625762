import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosConfig from "../Service/axiosConfig";
import { DashboardLayout, Navbar, Categories, ProductsList, SubCategories, Footer } from "../Components";
import { selectCartTotal, selectCartProducts } from "../redux/Slices/cartSlice";
import Modal from 'react-bootstrap/Modal';
import { ViewCart } from "../Components/Navbar/ViewCart";
import { Login } from "../Components/Login/Login";
import { SubCategoriesMobile } from "../Components/SubCategories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { BreadCrumbs } from "../Components/BreadCrums";
import { MobileNavBar } from "../Components/Navbar";
import "./Pages.css"
import { MdShoppingCart, MdOutlineHome, MdSearch, MdOutlineAddCircleOutline, MdWorkOutline, MdOtherHouses } from "react-icons/md";
import { BiLeftArrowAlt } from "react-icons/bi"
import { AiOutlineDown } from "react-icons/ai"
import { HiOutlineArrowLeft } from "react-icons/hi"
import ViewCartModal from "../Components/Navbar/ViewCartModal";
const SubCategoriesPage = (props) => {
    const { category_id, subcategory_id, friendlyurl } = props;
    console.log(category_id)
    const [CategorySlug, SetCategorySlug] = useState("")
    const [loading, setLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [Data, setData] = useState([])
    const [loginShow, setloginShow] = useState(false);
    const { cart } = useSelector((state) => state);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleLoginShow = () => setloginShow(true);
    const handleLoginClose = () => setloginShow(false);
    const [LoggedIn, setLoggedIn] = useState(false);
    const [Address, setAddress] = useState([])
    const [orderAddress, setOrderAddress] = useState()
    const [storeNull, setStoreNull] = useState(false)
    const [show, setShow] = useState(false);
    const total = useSelector(selectCartTotal);
    const products = useSelector(selectCartProducts);
    let ProductData = []
    const [count, setCount] = useState(1)
    const [NoStock, setNoStock] = useState([])
    const [Error, SetError] = useState("")
    const [Totalamount, setTotalAmount] = useState({
        Total_amount: 0
    })
    const [Order, setOrders] = useState({
        "address": "",
        "products": [],
        "stores": localStorage.getItem("stores"),
        "user_id": localStorage.getItem("userId"),
        "packing_charges": 0,
        "delivery_charges": 0,
    })
    const [smShow, setSmShow] = useState(false);
    const [popupType, setPopupType] = useState("")
    const [errorMsg, setErrorMsg] = useState(null)
    const [number, setNumber] = useState({})
    const [Step, SetStep] = useState("1")

    let Navigate = useNavigate()
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const HandleNavigate = (path) => {
        Navigate(path)
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/catlog/categories/${category_id}/`)
            SetCategorySlug(response.data?.slug)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const Getcategories = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/catlog/categories/?page_size=50&is_suspended=false")
            setData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const GetTheAddress = () => {
        setCount(2)
        GetAddressData()
    }
    const GetAddress = async (id) => {
        try {
            if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") { return null }
            const response = await axiosConfig.get(`/accounts/address/${id}/`)
            const store_response = await axiosConfig.get(`/stores/get_store/?latitude=${response.data.latitude}&longitude=${response.data.longitude}`);
            if (store_response.data.Message.store_id !== null && JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Hyperlocal") {
                localStorage.setItem("online", store_response.data.Message.is_online === "offline" ? true : false)
                localStorage.setItem("storeId", store_response.data.Message.store_id);
                localStorage.setItem("opens_at", store_response.data.Message.opens_at);
                localStorage.setItem("closes_at", store_response.data.Message.closes_at);
                localStorage.setItem("slot_duration", store_response.data.Message.slot_duration);
                // window.location.reload();
                setOrderAddress(response.data)
                localStorage.setItem("name", response.data.name);
                localStorage.setItem("address_id", response.data.id);
                localStorage.setItem("address", response.data.address)
                localStorage.setItem("latitude", response.data.latitude)
                localStorage.setItem("longitude", response.data.longitude)
                if (store_response.data.Message.is_online === "offline") {
                    localStorage.setItem("express_delivery", false)
                    localStorage.removeItem("express_delivery_time")
                }
                else {
                    localStorage.setItem("express_delivery", store_response.data.Message.express_delivery)
                    if (store_response.data.Message.express_delivery && store_response.data.Message.express_delivery_time) {
                        localStorage.setItem("express_delivery_time", store_response.data.Message.express_delivery_time)
                    }
                    else {
                        localStorage.removeItem("express_delivery_time")

                    }
                }
                cart.products.forEach(res => {
                    console.log(res)
                    ProductData.push({
                        "id": res.id,
                        "min_qty": res.quantity,
                    })
                })
                setOrders(
                    {
                        ...Order,
                        "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": id,
                        "products": ProductData,
                    }
                )
                setStoreNull(false)
            } else if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") {
                setOrderAddress(response.data)
                localStorage.setItem("name", response.data.name)
                localStorage.setItem("address_id", response.data.id)
                localStorage.setItem("address", response.data.address)
                localStorage.setItem("latitude", response.data.latitude)
                localStorage.setItem("longitude", response.data.longitude)
                cart.products.forEach(res => {
                    console.log(res)
                    ProductData.push({
                        "id": res.id,
                        "min_qty": res.quantity,
                    })
                })
                setOrders(
                    {
                        ...Order,
                        "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": id,
                        "products": ProductData,
                    }
                )
            } else {
                setStoreNull(true)
            }
        }
        catch (error) {
            console.log(error)
        }

    }
    const ChangeAddress = () => {
        setCount(2)
        setOrderAddress()
    }
    const MovetoCart = () => {
        setCount(1)
        setOrderAddress()
        setStoreNull(false)
    }
    const AddressLocal = async (id) => {
        if (localStorage.getItem("address_id")) {
            try {
                const response = await axiosConfig.get(`/accounts/address/${localStorage.getItem("address_id")}/`)
                setOrderAddress(response.data)
                cart.products.forEach(res => {
                    console.log(res)
                    ProductData.push({
                        "id": res.id,
                        "min_qty": res.quantity,
                    })
                })
                setOrders(
                    {
                        ...Order,
                        "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": localStorage.getItem("address_id"),
                        "products": ProductData,
                    }
                )
            }
            catch (error) {
                console.log(error)
            }
        }

    }
    const PayMentNavigate = async () => {
        let order_obj = Order;
        let request_type = 'post';
        let url = '/accounts/orders/';
        if (localStorage.getItem("orderId")) {
            order_obj['order_id'] = localStorage.getItem("orderId")
            request_type = 'put';
            url = `/accounts/orders/${localStorage.getItem("orderId")}/`;
        }
        try {
            axiosConfig.defaults.headers['Content-Type'] = 'application/json';
            const res = await axiosConfig[request_type](url, Order);
            localStorage.setItem("orderId", res.data.id);
            Navigate("/checkout");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data.message) {
                setSmShow(true);
                setErrorMsg(error.response.data.message);
            }
            if (error.response && error.response.data["The following products are not available in the store:"]) {
                error.response.data["The following products are not available in the store:"].forEach(async (res) => {
                    try {
                        const productRes = await axiosConfig.get(`/catlog/products/${res.id}/?is_suspended=false`);
                        setNoStock(prestate => [...prestate, productRes.data]);
                        setSmShow(true);
                    } catch (error) {
                        console.error(error);
                    }
                });
            }
        }
    }
    const HandleAddress = () => {
        setPopupType("success")
    }
    const GetAddressData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/address/?user=${localStorage.getItem("userId")}&is_suspended=false`)
            setAddress(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const HndleSmallShow = () => {
        setSmShow(false)
        setNoStock("")
        setErrorMsg(null)
    }
    function handleLoginEvent() {
        handleLoginClose(false);
        setLoggedIn(true)
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setLoggedIn(true)
        }
    }
    const HandleModal = () => {
        SetStep(1)
        setNumber({})
    }
    useEffect(() => {
        GetData();
        GetAddressData()
        AddressLocal()
        Getcategories()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [category_id])
    function formatOpeningTime(openingTime) {
        // Assuming openingTime is in 24-hour format HH:mm
        const [hours, minutes] = openingTime.split(':');
        const formattedHours = parseInt(hours) % 12 || 12;
        const amOrPm = parseInt(hours) >= 12 ? 'PM' : 'AM';
        return `${formattedHours}:${minutes} ${amOrPm}`;
    }
    const HandleFunction = (para) => {
        console.log(para)
    }
    return (
        <>
            {isMobile ? <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-common">
                        <div className="navmbf1" onClick={() => HandleNavigate("/")}>
                            <BiLeftArrowAlt className="leftarroworder" />
                        </div>
                        <div className="navmbf2">
                            <div className="nav-mobile-time">
                                {localStorage.getItem("site_data").ecommerce_type === "Hyperlocal" ? (localStorage.getItem("express_delivery_time") ? `Delivery in ${localStorage.getItem("express_delivery_time")} minutes` : "Delivering to") : "Delivering to"}
                            </div>
                           {JSON.parse(localStorage.getItem("site_data"))
                    ?.ecommerce_type === "Hyperlocal"&&  <div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>}
                        </div>
                        <div className="navmbf3">
                            <NavLink to="/s" >
                                <MdSearch className="search-mdicon" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="top-info-container-mobile">
                        <p>{friendlyurl}</p>
                    </div>
                </header>
                {cart.products.length > 0 && (
                    < div className="btn btn-success cart-container show-tablet" onClick={handleShow}>
                        <div className="cart-icon-holder">
                            <MdShoppingCart />
                        </div>
                        <div className="cart-qty">
                            {cart.products.length > 0 ? (
                                <p>{cart.products.length} items</p>
                            ) : (
                                <p>My Cart</p>
                            )}
                        </div>
                        <span className="p-price">₹{parseFloat(Totalamount.Total_amount - Order.packing_charges - Order.delivery_charges)}</span>
                    </div>
                )}
                <ViewCartModal
                    count={count}
                    setCount={setCount}
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                    orderAddress={orderAddress}
                    setOrderAddress={setOrderAddress}
                    setOrders={setOrders}
                    Order={Order}
                    ProductData={ProductData}
                    setTotalAmount={setTotalAmount}
                    products={products}
                    ChangeAddress={ChangeAddress}
                    GetTheAddress={GetTheAddress}
                    PayMentNavigate={PayMentNavigate}
                    handleLoginShow={handleLoginShow}
                    cart={cart}
                    Totalamount={Totalamount}
                    isMobile={isMobile}
                    MovetoCart={MovetoCart}
                    HandleAddress={HandleAddress}
                    storeNull={storeNull}
                    Address={Address}
                    GetAddress={GetAddress}
                    travelTime={localStorage.getItem("express_delivery_time")}

                />
                <Modal
                    size="sm"
                    show={smShow}
                    onHide={HndleSmallShow}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Body>
                        {
                            NoStock.length >= 1 ? NoStock.map((res, i) => {
                                console.log(res)
                                return (
                                    <div key={i} className="p-avalibile" >
                                        {res.name}<br />
                                    </div>
                                )
                            }) : <div className="p-avalibile">
                                {NoStock.name}
                            </div>
                        }
                        {!errorMsg &&
                            <div className="not-avalible text-center">
                                Not Available in Selected Store
                            </div>
                        }
                        {errorMsg &&
                            <div className="min-value-error">{errorMsg}</div>
                        }
                    </Modal.Body>
                    <Modal.Footer >
                        <button className="btn btn-secondary" onClick={HndleSmallShow}>Ok</button>
                    </Modal.Footer>
                </Modal>
                <Modal aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                    <Modal.Header closeButton>
                        {/* <Modal.Title>login/signup</Modal.Title> */}
                        {number.mobile ? <Modal.Title className="modal-tile-back" onClick={HandleModal}>Back</Modal.Title> : <Modal.Title className="login-head__text weight--light">Phone Number Verification</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Login handleLoginEvent={handleLoginEvent} setNumber={setNumber} number={number} SetStep={SetStep} Step={Step} />
                    </Modal.Body>
                    <Modal.Footer className="loginfooter">
                        <p>By continuing, you agree to our</p>
                        <NavLink to="/terms-services">Terms of service</NavLink>
                        <NavLink to="/privacy">Privacy policy</NavLink>
                    </Modal.Footer>
                </Modal>
            </div> : <><Navbar />
                <section className="sub-categories-menu">
                    <div className="container">
                        <div className="subcat-items">
                            {
                                Data.map((item, i) => {
                                    if (i < 7) {
                                        return (
                                            <div className="subcat-item" key={i}>
                                                {/* <NavLink to={`/${item.slug}`} >{item.name}</NavLink> */}
                                                <div onClick={() => HandleNavigate(`/${item.slug}`)}>{item.name}</div>
                                            </div>
                                        )
                                    }

                                })
                            }
                            <div className="subcat-item">
                                <a className="dropdown-toggle no-after" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    More <AiOutlineDown className="drop-icon" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {
                                        Data.map((option, i) => {
                                            if (i > 6) {
                                                console.log(option)
                                                return (
                                                    // <NavLink to={`/${option.slug}`} className="dropdown-item">{option.name}</NavLink>
                                                    <div onClick={() => HandleNavigate(`/${option.slug}`)} className="dropdown-item">{option.name}</div>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            }
            <div className="container">
                <div className="catflexpage">
                    <div className="catdflex">
                        <div className="catfl-left">
                            <div className="subcategories-list">
                                <SubCategories category_id={category_id} category_slug={CategorySlug} />
                            </div>
                        </div>
                        <div className="catfl-right">
                            <div className="matched-products-list">
                                <ProductsList subcategory_id={subcategory_id} friendlyurl={friendlyurl} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export { SubCategoriesPage }