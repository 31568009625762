import React, { useEffect, useState } from "react"
import "./Login.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { addWishlist } from "../../redux/Slices/cartSlice";

const Login = ({ handleLoginEvent,setNumber,number,SetStep,Step,Setlogin,LoginAccount }) => {
    let navigate = useNavigate();
    const [userId, setUserId] = useState()
    const [Data, SetData] = useState({
        mobile: "",
        otp: ""
    })
    const [error, SetError] = useState("")
    const InputHandleer = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        SetData({ ...Data, [name]: value })
    }
    const [loginSuccess, SetloginSuccess] = useState(false)
    const ValidateMobile = (e) => {
        e.preventDefault()
        setNumber({...number,"mobile":Data.mobile})
        if (Data.mobile.length < 10 || Data.mobile.length > 10) {
            SetError("Enter A Valid Mobile number")
        }
        else {
            axiosConfig.post("/accounts/validate_mobile/", { 'mobile': Data.mobile }).then((res) => {
                SetStep(2)
                console.log(res.data.message)
                SetError("")
            }).catch(e => {
                console.log(e)
            })
        }
    }
    const VerifyOtp = (e) => {
        e.preventDefault()
        axiosConfig.post("/accounts/verify_otp/", { 'mobile': Data.mobile, 'otp': Data.otp }).then((res) => {
            console.log(res, "otp posted")
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userId', res.data.user_id);
            setUserId(res.data.user_id)
            GetData(res.data.user_id)
            // localStorage.setItem('wishlists', JSON.stringify(res.data.results));
            localStorage.setItem('number', Data.mobile);
            SetloginSuccess(true)
            handleLoginEvent()
            Setlogin&&Setlogin(!LoginAccount)
        }).catch(e => {
            console.log(e)
            if (e.response.data.message === "Verification failed.") {
                SetError("Invalid OTP. Please try again.")
            }
        })
    }
    let page = 1;
    const dispatch = useDispatch();
    // const[isDeleted,setIsDeleted]=useState(false)
    const GetData = async (user) => {
        console.log("inside get data")
        try {
            const response = await axiosConfig.get(`/catlog/wishlists/?page_size=20&page=${page}&user=${user}`)
            console.log(response.data.results)
            response.data.results.map((item) => {
                dispatch(addWishlist({ productId: item.product.id, wish_id: item.id }));
            })
            // setIsDeleted(false)
        }
        catch (error) {
            console.log(error)
        } 
    }
    // useEffect(() => {
    //     userId && GetData(userId);
    // },[isDeleted, userId])
    return (
        <>
            {loginSuccess ? (
                <>
                    <h4 className="login-success">Loggin Successful</h4>
                </>
            ) : (
                <>

                    {Step == '1' ? (
                        <form className="login-form">
                            {/* <p className="">Enter your phone number to </p>
                            <p className="">Login/Sign up</p> */}
                            <div className="login-header-main">
                                <div className="login-help weight--semibold">
                                    Enter your phone number to
                                </div>
                                <div className="login-help weight--semibold">
                                    Login/Sign up
                                </div>
                            </div>
                            <div className="form-group">
                                <input name="mobile" className="form-control" placeholder="Mobile Number" value={Data.mobile} onChange={InputHandleer} />
                                <div className="color-error">
                                    {error && error}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-next" disabled={Data.mobile.length !== 10} onClick={ValidateMobile}>Next</button>
                        </form>
                    ) :
                        (
                            <form className="otp-form">
                                <p className="">Enter 4 digit code sent to your phone</p>
                                <p>+91-{Data.mobile}</p>
                                <div className="form-group">
                                    <input name="otp" className="form-control" placeholder="OTP" value={Data.otp} onChange={InputHandleer} />
                                    <div className="color-error">
                                        {error && error}
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-next" disabled={Data.otp.length !== 4} onClick={VerifyOtp}>Next</button>
                            </form>
                        )}
                </>

            )}
        </>
    )
}
export { Login };