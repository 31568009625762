import React, { useEffect,useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Login } from "../Login";
import { NavLink } from "react-router-dom";
const LoginModal=({LoginType,setLoginType,setLoggedIn,LoggedIn})=>{
    const [loginShow, setloginShow] = useState(false);
    const [Step, SetStep] = useState("1")
    const [number, setNumber] = useState({})
    const handleLoginShow = () => setloginShow(true);
    const handleLoginClose = () => {
        setLoginType(false);
        SetStep(1)
        setNumber({})
    }
    const HandleModal = () => {
        SetStep(1)
        setNumber({})
    }
    function handleLoginEvent() {
        handleLoginClose(false);
        setLoggedIn&&setLoggedIn(!LoggedIn)
    }
    const getLogin=()=>{
        if(LoginType){
            handleLoginShow()
        }
        else{
            handleLoginClose(false); 
        }
    }
    useEffect(()=>{
      getLogin();
    },[LoginType])
    return(
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
        <Modal.Header closeButton>
            {number.mobile ? <Modal.Title className="modal-tile-back" onClick={HandleModal}>Back</Modal.Title> : <Modal.Title className="login-head__text weight--light">Phone Number Verification</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
            <div className="login-head">
                <h2 className="login-head__text weight--light">Phone Number Verification</h2>
            </div>
            <Login handleLoginEvent={handleLoginEvent} setNumber={setNumber} number={number} SetStep={SetStep} Step={Step} />
            <div className="loginfooter">
                <p>By continuing, you agree to our</p>
                <NavLink to="/terms-services">Terms of service</NavLink>
                <NavLink to="/privacy">Privacy policy</NavLink>
            </div>
        </Modal.Body>
    </Modal>
    )
}
export {LoginModal}