import React, { useState, useEffect } from "react";
import "./Products.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import Product from "./Product";
import SubProduct from "./SubProduct";
const ProductsList = (props) => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])
    const [DataSort, SetDatasort] = useState([])
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [brand, setBrand] = useState('');
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;

    let subcategory_id = props.subcategory_id
    const GetData = async (subcategory_id) => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/products/?subcategory=${subcategory_id}&page_size=20&category=${category}&brand=${brand}&price=${price}&discount=${discount}&store=${localStorage.getItem("storeId")}&is_suspended=false`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            SetDatasort(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const fetchData = async (url, setItems) => {
        let allData = [];
        let nextUrl = url;
      
        while (nextUrl) {
          try {
            // Check if nextUrl starts with 'http:' and replace it with 'https:'
            if (nextUrl.startsWith('http:')) {
              nextUrl = nextUrl.replace(/^http:/, 'https:');
            }
      
            const response = await axiosConfig.get(nextUrl);
            allData = allData.concat(response.data.results);
            nextUrl = response.data.next;
          } catch (error) {
            console.log("Error getting data: ", error);
            break; // Exit the loop on error
          }
        }
      
        setItems(allData);
      };
      

    const handleInfiniteScroll = async () => {
        // console.log("scrollHeight" + document.documentElement.scrollHeight);
        // console.log("innerHeight" + window.innerHeight);
        // console.log("scrollTop" + document.documentElement.scrollTop);
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 500 >=
                document.documentElement.scrollHeight
            ) {
                console.log("inside infinite scroll", page, totalPages)
                if (page < totalPages) {
                    setLoading2(true)
                    page = page + 1
                    let resp = await axiosConfig.get(`/catlog/products/?subcategory=${subcategory_id}&page=${page}&page_size=20&store=${localStorage.getItem("storeId")}&is_suspended=false`)
                    SetData((Data) => [...Data, ...resp.data.results])
                    SetDatasort((Data) => [...Data, ...resp.data.results])
                    setLoading2(false)
                } else {
                    setLoading2(false)
                }

            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleValueChange = (e) => {
        const { name, value } = e.target
        if (name === "price") {
            setPrice(value)
        }
        if (name === "discount") {
            setDiscount(value)
        }
    }

    const handleBlur = async () => {
        try {
            const response = await axiosConfig.get(`/catlog/products/?subcategory=${subcategory_id}&page_size=20&price=${price}&discount=${discount}&store=${localStorage.getItem("storeId")}&is_suspended=false`)
            console.log("Inside handleBlur : ", response.data.results)
            SetData(response.data.results)
            SetDatasort(response.data.results)
        } catch (error) {
            console.log("Error filtering discount and price : ", error)
        }
    }

    const handleCategoryChange = (e) => {
        const { value } = e.target
        setCategory(value)
        navigate(`/${categories.find((c) => c.id === JSON.parse(value)).subcategory_slug}`)
    }

    useEffect(() => {
        GetData(subcategory_id);
        fetchData('/catlog/categories/?page_size=50&is_suspended=false', setCategories);
        fetchData('/catlog/brands/?is_suspended=false', setBrands);
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [subcategory_id, category, brand])

    const HandleSort = (e) => {
        const { name, value } = e.target
        let data = []
        if (value === "Relevance") {
            console.log(value)
            data = DataSort
        }
        if (value === "Price (Low to High)") {
            console.log(value)
            data = [...DataSort].sort((a, b) => a.price - b.price);
        }
        if (value === "Price (High to Low)") {
            console.log(value)
            data = [...DataSort].sort((a, b) => b.price - a.price);
        }
        if (value === "Discount (High to Low)") {
            console.log(value);
            data = [...DataSort].sort((a, b) => {
                const aOfferPrice = parseFloat(a.offer_price) || 0;
                const bOfferPrice = parseFloat(b.offer_price) || 0;
                // Higher offer_price should come first
                return bOfferPrice - aOfferPrice;
            });
        }
        if (value === "Name (A to Z)") {
            console.log(value)
            data = [...DataSort].sort((a, b) => a.name.localeCompare(b.name));
        }
        console.log(data)
        SetData(data)
    }

    return (
        <>

            <div className="products-container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        <div className="inner-container">
                            <div className="top-info-container">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <p className="subcategory-title">{props.friendlyurl}</p>
                                    </div>
                                    {/* <div className="d-flex flex-column">
                                        <label className="form-label">Category</label>
                                        <select className="Selectbar" value={category} onChange={(e) => handleCategoryChange(e)}>
                                            <option value="">Select Category</option>
                                            {categories && categories.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="form-label">Price</label>
                                        <input className="Selectbar value" type="text" name="price" value={price} onChange={(e) => handleValueChange(e)} onBlur={handleBlur} />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="form-label">Brand</label>
                                        <select className="Selectbar" value={brand} onChange={(e) => setBrand(e.target.value)}>
                                            <option value="">Select Brand</option>
                                            {brands.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.brand_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="form-label">Discount</label>
                                        <input className="Selectbar value" type="text" name="discount" value={discount} onChange={(e) => handleValueChange(e)} onBlur={handleBlur} />
                                    </div> */}
                                    <div className="d-flex flex-column">
                                        <label for="sortby" className="form-label">Sort By</label>
                                        <select className="Selectbar" id="sortby" name="sellist1" onChange={HandleSort}>
                                            <option>Relevance</option>
                                            <option>Price (Low to High)</option>
                                            <option>Price (High to Low)</option>
                                            <option>Discount (High to Low)</option>
                                            <option>Name (A to Z)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Data.length > 0 &&
                            <>
                                <div className="catagery-items-grid">
                                    {
                                        Data.map((itemObj, index) => {
                                            console.log(itemObj)
                                            return (
                                                <div className="catagery-item-product" key={index}>
                                                    <Product item={itemObj} index={index} />
                                                </div>
                                            )
                                        })
                                    }
                                    {/* another loader */}
                                    {loading2 && (
                                        <div className="loader-container">
                                            <img src={require('../images/loader.gif')} alt="Loading..." />
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    </>
                )}
            </div>

        </>
    )
}
export { ProductsList };