import React from "react";
import { AiOutlineLike } from "react-icons/ai";

import { useNavigate } from "react-router-dom";

const BlogItem = ({item,params,category}) => {
    const navigate = useNavigate();
    const handleBlogClick = () => {
        console.log(item)
        navigate(`/blog/${item?.category?.slug}/${item?.slug}/`);
    }
    return (
        <div className="blog" onClick={() => handleBlogClick()}>
            {/* <div className="info-top">
                <div className="img-container">
                    <img src={require('../../assets/images/blog1.png')} />
                </div>
                <span style={{ marginRight: '16px' }}>Kyle Scanlan</span>
                <span>Mar 27, 2024</span>
            </div> */}
            <div className="content">
                <div>
                    <div className="overflowWrap">
                        <h3 style={{color:"#000"}}>{item?.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item?.content }} style={{textAlign:'justify'}}/>
                    </div>
                </div>
                <div className="img-container" style={{border:"none",background:"none"}}>
                    <img src={item?.image?.image} style={{ width: '100%', height: '100%' }} />
                </div>
            </div>
            <div className="bottom">
                <div>
                    <span className="topic">#{item?.category.title}</span>
                    {/* <span style={{ marginLeft: '12px' }}>2 min read</span> */}
                </div>
                {/* <div>
                    <AiOutlineLike size={16} color="black" style={{ marginRight: '2px' }} />
                    <span style={{ marginRight: '25px' }}>100</span>
                    <PiShareFat size={16} color="black" style={{ marginRight: '2px' }} />
                    <span>10</span>
                </div> */}
            </div>
        </div>
    )
}

export default BlogItem